import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getCoupons,
  toggleCoupon,
} from '@util/firestore/coupon/coupon.service';
import { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';

export function useSellerCoupons({
  seller_id,
  is_private,
  is_active,
}: {
  seller_id?: string;
  is_private?: boolean;
  is_active?: boolean;
}) {
  const { userDoc } = useAuth();
  const { showSuccessToast, showErrorToast } = useToastContext();

  const data = useQuery({
    queryKey: ['user_coupons', userDoc?.uid],
    queryFn: () =>
      userDoc
        ? getCoupons({
            uid:
              userDoc.uid === process.env.NEXT_PUBLIC_SUPPORT_ID
                ? undefined
                : userDoc.uid,
            is_private,
            is_active,
          })
        : [],
    throwOnError: true,
  });

  const sellerCouponsData = useQuery({
    queryKey: ['coupons', seller_id],
    queryFn: () =>
      seller_id ? getCoupons({ uid: seller_id, is_private, is_active }) : [],
    throwOnError: true,
  });

  const toggleCouponMutation = useMutation({
    mutationFn: toggleCoupon,
    onSuccess: () => {
      showSuccessToast('Coupon updated');
      data.refetch();
    },
    onError: (error) => {
      showErrorToast((error as Error).message, 'top-center', 3000);
    },
  });

  return {
    userCoupons: data.data,
    toggleCouponMutation,
    sellerCoupons: sellerCouponsData.data,
  };
}
