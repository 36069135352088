import { ChevronLeftIcon, ChevronRightIcon } from '@c/icons';
import SafeImage from '@ui/SafeImage';
import { useEffect, useState } from 'react';
import BaseModal from './BaseModal';
import { ProductDocument } from '@models/product';
interface ProductViewerModalProps {
  isOpen: boolean;
  dismiss: () => void;
  images?: ProductDocument['images'];
  video?: ProductDocument['video'];
  initialImage?: number;
}
const ProductViewerModal = ({
  isOpen,
  dismiss,
  images,
  video,
  initialImage = 0,
}: ProductViewerModalProps) => {
  const [currentImage, setCurrentImage] = useState(initialImage);
  const [zoomedIn, setZoomedIn] = useState(false);

  useEffect(() => {
    setZoomedIn(false);
  }, [currentImage]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!images) return;
      if (e.key === 'ArrowLeft') {
        if (currentImage > 0) {
          setCurrentImage(currentImage - 1);
        } else {
          setCurrentImage(images.length - 1);
        }
      } else if (e.key === 'ArrowRight') {
        if (currentImage < images.length - 1) {
          setCurrentImage(currentImage + 1);
        } else {
          setCurrentImage(0);
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [currentImage, images]);

  if (!images) return null;

  const full = images?.[currentImage]?.full;
  const thumb = images?.[currentImage]?.thumb;
  const download_url = images?.[currentImage]?.download_url;
  return (
    <BaseModal isOpen={isOpen} dismiss={dismiss} isFullScreen bgTransparent>
      <div className="flex h-full w-full flex-col items-center overflow-auto">
        <div className="flex w-full grow justify-between">
          {/* left button */}
          <button
            onClick={() => {
              if (currentImage > 0) {
                setCurrentImage(currentImage - 1);
              } else if (video && currentImage !== -1) {
                setCurrentImage(-1);
              } else {
                setCurrentImage(images.length - 1);
              }
            }}
            className="text-white"
          >
            <ChevronLeftIcon width={44} height={44} />
          </button>
          {/* image */}
          <div className="relative z-10 max-w-[80%] grow overflow-auto">
            {currentImage === -1 && video ? (
              <video
                autoPlay
                loop
                muted
                playsInline
                draggable={false}
                src={video.full}
                className="mx-auto max-h-[80vh]"
              ></video>
            ) : (
              <SafeImage
                key={full}
                onClick={() => setZoomedIn(!zoomedIn)}
                alt="current image"
                src={full}
                fallbackSrc={download_url}
                blurDataURL={thumb}
                draggable={false}
                skipOptimize
                fill
                className={`object-contain ${
                  zoomedIn ? 'scale-[175%] cursor-zoom-out' : 'cursor-zoom-in'
                }`}
              />
            )}
          </div>
          {/* right button */}
          <button
            onClick={() => {
              if (currentImage < images.length - 1) {
                setCurrentImage(currentImage + 1);
              } else if (video) {
                setCurrentImage(-1);
              } else {
                setCurrentImage(0);
              }
            }}
            className="text-white"
          >
            <ChevronRightIcon width={44} height={44} />
          </button>
        </div>
        <div className="mt-[1.6rem] flex justify-center gap-[1.6rem] overflow-x-auto">
          {video && (
            <video
              loop
              muted
              playsInline
              draggable={false}
              src={video.full}
              className={`h-[8rem] w-[8rem] cursor-pointer bg-white transition-opacity ${
                currentImage !== -1 ? 'opacity-50' : ''
              }`}
              onClick={() => setCurrentImage(-1)}
            ></video>
          )}

          {images.map((image, index) => (
            <SafeImage
              onClick={() => setCurrentImage(index)}
              className={`h-[8rem] w-[8rem] cursor-pointer bg-white transition-opacity ${
                index !== currentImage ? 'opacity-50' : ''
              }`}
              draggable={false}
              key={image.thumb + `${index}`}
              height={100}
              width={100}
              src={image.thumb}
              fallbackSrc={image.download_url}
              alt={`image ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </BaseModal>
  );
};

export default ProductViewerModal;
