import { useQuery } from '@tanstack/react-query';
import SafeImage from '@ui/SafeImage';
import { getProductById } from '@util/firestore/products';
import { useOffers } from './useOffers';
import { formatCurrency } from '@util/index';

export default function MobileMessageOffer({ offer_id }: { offer_id: string }) {
  const { offer } = useOffers(offer_id);

  const { data: product } = useQuery({
    queryKey: ['product', offer?.product_id],
    queryFn: () => getProductById(offer!.product_id),
    enabled: !!offer?.product_id,
  });

  if (!product) return null;

  const showSizes = () => {
    const sizes = [];

    if (offer?.variation?.letter) sizes.push(offer?.variation?.letter);
    if (offer?.variation?.number) sizes.push(offer?.variation?.number);

    return sizes.join('/');
  };

  return (
    <div className="flex h-fit w-full gap-[1.5rem] p-[1.5rem]">
      <div className="relative h-[5.5rem] w-[5.5rem]">
        <SafeImage src={product.thumbnail} fill alt="product image" />
      </div>

      <div className="flex flex-col">
        <div className="flex w-full justify-between">
          <span
            className={`line-clamp-1 h-[2.875rem] ${
              offer?.variation && 'w-[30rem]'
            } text-[1.875rem] font-medium leading-[2.875rem]`}
          >
            {product.title}
          </span>

          {!!offer?.variation && (
            <div className="rounded-[1rem] bg-brand-lightest-gray px-4 py-2">
              <span className="text-[1.8rem] font-semibold">
                Size: {showSizes()}
              </span>
            </div>
          )}
        </div>

        <span className="text-[1.625rem] font-semibold text-[#7F7F7F]">
          {formatCurrency(product.price)}
        </span>
      </div>
    </div>
  );
}
