import { SoldOverlay } from '@c/dashboard/pages/ListingsPage';
import { ArrowUpRightIcon } from '@c/icons';
import BuyAddOfferButtons from '@c/product-details/BuyAddOfferButtons';
import ProductInfo from '@c/products/ProductInfo';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import Spinner from '@ui/Spinner';
import { getProductById } from '@util/firestore/products';
import { getPublicUserDoc } from '@util/firestore/users';
import { formatCurrency } from '@util/index';
import { getProductSlug } from '@util/urlHelpers';
import { useAuth } from 'context/AuthContext';
import Link from 'next/link';

const ProductPreview = ({ productId }: { productId: string }) => {
  const { userDoc } = useAuth();

  const { data: product, isLoading: isLoadingProduct } = useQuery({
    queryKey: ['product', productId],
    queryFn: () => getProductById(productId),
    enabled: !!productId,
  });

  const { data: seller } = useQuery({
    queryKey: ['publicUser', product?.seller_id],
    queryFn: () => getPublicUserDoc({ uid: product?.seller_id! }),
    enabled: !!product?.seller_id,
  });

  if (isLoadingProduct) {
    return (
      <div className="mx-auto h-[10rem] w-[10rem]">
        <Spinner />
      </div>
    );
  }
  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <>
      <div className="hidden h-full min-w-[40rem] flex-col  gap-[2.4rem] border-l-[1px] border-[#d9d9d9] p-[2.4rem] @4xl:flex">
        <div className="flex flex-col gap-[1.6rem]">
          <Link
            href={getProductSlug(product)}
            className="relative h-[35.2rem] w-[35.2rem]"
          >
            <SafeImage
              src={product.thumbnail}
              key={product.thumbnail}
              fill
              alt="product image"
            />
            {product.out_of_stock && <SoldOverlay variant={'sold'} />}
          </Link>
          <div className="flex flex-col gap-[0.4rem]">
            <span className="text-[2.2rem] font-semibold">{product.title}</span>
            <span className="text-[2.4rem] font-semibold">
              {formatCurrency(product.price)}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-[1.2rem]">
          {product.category === 'Dirt Bikes' ? (
            <Button
              height="small"
              type="secondary"
              width="fluid"
              text="View Bike"
              href={getProductSlug(product)}
            />
          ) : product.has_variations ? (
            <Button
              height="small"
              type="secondary"
              width="fluid"
              text="View item"
              href={getProductSlug(product)}
            />
          ) : seller?.uid !== userDoc?.uid && !product.is_auction ? (
            <BuyAddOfferButtons
              product={product}
              seller={seller}
              selectedVariations={null}
              onAddToCartError={() => {}}
            />
          ) : null}
        </div>
      </div>
      <div className="flex items-center justify-between gap-[1.2rem] bg-brand-lightest-gray px-[1.6rem] py-[1.2rem] @4xl:hidden">
        <ProductInfo product={product} />
        <Button
          type="text"
          leadingIcon={<ArrowUpRightIcon />}
          width="extraSmall"
          height="small"
          href={getProductSlug(product)}
        />
      </div>
    </>
  );
};

export default ProductPreview;
