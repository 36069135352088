import { getVariationPrice } from '@util/firestore/products';
import { CartItem } from '@util/types/firestore/carts';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { ProductDocument, Variation } from 'models/product';
import { useMemo, useState, useEffect, useCallback } from 'react';

const useBuyerActionButtons = (product: ProductDocument) => {
  const [selectedVariations, setSelectedVariations] = useState<
    Variation[] | null
  >(null);
  const [errors, setErrors] = useState<string[]>([]);
  const { cart, updateCartItem } = useShoppingCart();
  const cartItem = useMemo(
    () => cart?.items.find((c) => c.product_id === product.id),
    [cart, product.id]
  );
  const onSelection = (v: Variation, isNumber: boolean, isColor: boolean) => {
    const prev = selectedVariations;
    let newSelectedVariations = null;
    let existingVariations = [];
    if (isNumber) {
      existingVariations = prev?.filter((v) => !v.is_number || v.color) ?? [];
    } else if (isColor) {
      existingVariations = prev?.filter((v) => !v.color) ?? [];
    } else {
      existingVariations = prev?.filter((v) => v.is_number || v.color) ?? [];
    }
    if (existingVariations.length) {
      newSelectedVariations = [v, ...existingVariations];
    } else {
      newSelectedVariations = [v];
    }
    setSelectedVariations(newSelectedVariations);
    setErrors((prev) =>
      prev.filter((e) => e !== (isNumber ? 'Pant' : 'Jersey'))
    );
    if (
      cartItem &&
      newSelectedVariations &&
      newSelectedVariations?.length >= 1
    ) {
      const letter = newSelectedVariations.find((v) => isNaN(+v.size))
        ?.size as string;
      const number = newSelectedVariations.find((v) => !isNaN(+v.size))
        ?.size as number;
      const color = newSelectedVariations.find((v) => v.color)?.color as string;
      updateCartItem({
        ...cartItem,
        product_cost: getVariationPrice(product, newSelectedVariations),
        size: {
          ...(letter && { letter }),
          ...(number && { number }),
          ...(color && { color }),
        },
        color: newSelectedVariations.find((v) => v.color)?.color ?? '',
      });
    }
  };

  const getInitialVariations = useCallback(
    (
      product: ProductDocument,
      cartItem?: CartItem,
      params?: URLSearchParams
    ) => {
      const variations = [];
      // if the cart item has a letter but no size, return the matching variation in an array
      if (cartItem?.size?.letter) {
        const tmp = product.variations!.find(
          (v) => v.size === cartItem.size!.letter
        );
        const lv = tmp ? { ...tmp } : null;
        if (lv) {
          delete lv.color;
        }
        variations.push(lv);
      }
      // if the cart item has a size but no letter, return the matching variation in an array
      if (cartItem?.size?.number) {
        const tmp = product.variations!.find(
          (v) => v.size === cartItem.size!.number
        );
        const nv = tmp ? { ...tmp } : null;
        variations.push(nv);
      }
      if (cartItem?.color) {
        const tmp = product.variations!.find(
          (v) => v.color === cartItem.color
        )!;
        const cv = tmp ? { ...tmp } : null;
        if (cv) {
          cv.size = '';
        }
        variations.push(cv);
      }
      if (variations.length) {
        return variations.filter((v) => v) as Variation[];
      }

      // if the cart item has no size or letter, check the query string
      // if the query string has a size, return the matching variation in an array
      if (params?.has('size')) {
        const size = params.get('size');
        if (size) {
          const sizes = size.split(',');
          const numberSize = sizes.find((s) => !isNaN(+s));
          const letterSize = sizes.find((s) => isNaN(+s));
          // if the cart item has a letter and size, return the matching variations in an array
          if (letterSize && numberSize) {
            return [
              product.variations!.find((v) => v.size === letterSize),
              product.variations!.find((v) => v.size === +numberSize),
            ].filter((v) => v) as Variation[];
          }
          // if the cart item has a letter but no size, return the matching variation in an array
          else if (letterSize) {
            return [
              product.variations!.find((v) => v.size === letterSize),
            ].filter((v) => v) as Variation[];
          }
          // if the cart item has a size but no letter, return the matching variation in an array
          else if (numberSize) {
            return [
              product.variations!.find((v) => v.size === +numberSize),
            ].filter((v) => v) as Variation[];
          }
        }
      }
      // auto select the first variation in stock
      const defaultLetterVariation =
        product.variations?.filter((v) => v.qty > 0 && isNaN(+v.size)) ?? null;
      const lv = defaultLetterVariation?.[0]
        ? { ...defaultLetterVariation[0] }
        : null;
      if (lv) {
        delete lv.color;
      }
      const defaultNumberVariation =
        product.variations?.filter(
          (v) => v.qty > 0 && !isNaN(+v.size) && v.is_number
        ) ?? null;
      const nv = defaultNumberVariation?.[0]
        ? { ...defaultNumberVariation[0] }
        : null;
      const defaultColorVariation =
        product.variations?.filter((v) => v.qty > 0 && v.color) ?? null;
      const cv = defaultColorVariation?.[0]
        ? { ...defaultColorVariation[0] }
        : null;
      if (cv) {
        cv.size = '';
      }
      return [lv, nv, cv].filter((v) => v) as Variation[];
    },
    []
  );

  useEffect(() => {
    if (!selectedVariations?.length && product.has_variations && cartItem) {
      setSelectedVariations(getInitialVariations(product, cartItem));
    }
  }, [product, getInitialVariations, cartItem, selectedVariations?.length]);

  return {
    selectedVariations,
    setSelectedVariations,
    errors,
    setErrors,
    cartItem,
    updateCartItem,
    onSelection,
  };
};

export default useBuyerActionButtons;
