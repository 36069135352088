import { db, functions, httpsCallable } from '@util/firebase';
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { Coupon, couponSchema } from 'models/coupon';

const couponsRef = collection(db, 'coupons');

export async function getCoupons({
  uid,
  is_active,
  is_private,
}: {
  uid?: string;
  is_active?: boolean;
  is_private?: boolean;
}) {
  const q = query(
    couponsRef,
    ...(uid ? [where('seller_id', '==', uid)] : []),
    ...(is_active !== undefined ? [where('active', '==', is_active)] : []),
    ...(is_private !== undefined ? [where('is_private', '==', is_private)] : [])
  );
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => doc.data()) as Coupon[];
}

export async function createCoupon(coupon: Coupon) {
  const parsedCoupon = couponSchema.parse(coupon);
  const res = await httpsCallable<{ coupon: Coupon }, Coupon>(
    functions,
    'v2_createCoupon'
  )({ coupon: parsedCoupon });
  return res.data;
}

export async function toggleCoupon(coupon: Coupon) {
  const ref = doc(couponsRef, coupon.id);
  return await updateDoc(ref, {
    active: !coupon.active,
  });
}

export async function deactivateCoupon(coupon: Coupon) {
  const ref = doc(couponsRef, coupon.id);
  return await updateDoc(ref, {
    active: false,
  });
}

export async function getActiveCouponByCode(code: string) {
  const q = query(
    couponsRef,
    where('code', '==', code),
    where('active', '==', true)
  );
  const snapshot = await getDocs(q);
  return snapshot.docs[0]?.data() as Coupon;
}

export function getCouponId(): string {
  const newDocRef = doc(couponsRef);
  const id = newDocRef.id;
  return id;
}
