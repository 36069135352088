import SafeImage from './SafeImage';
import { cva, VariantProps } from 'class-variance-authority';
import { useNotifications } from '@util/hooks/useNotifcations';
import { PublicUserDocument } from '@models/user';
import { ShieldCheckIcon } from '@heroicons/react/20/solid';
import { useQuery } from '@tanstack/react-query';
import { getUserByUsername } from '@util/firestore/users';
import { useAuth } from 'context/AuthContext';

const styles = cva(
  'relative rounded-full overflow-hidden flex items-center justify-center',
  {
    variants: {
      size: {
        mxExtraSmall: 'h-[2.8rem] w-[2.8rem]',
        extraSmall: 'h-[3.2rem] w-[3.2rem]',
        small: 'h-touch w-touch',
        medium: 'h-[5.6rem] w-[5.6rem]',
        mlarge: 'h-[8rem] w-[8rem]',
        large: 'h-[9.6rem] w-[9.6rem]',
      },
    },
    defaultVariants: {
      size: 'medium',
    },
  }
);
interface AvatarProps extends VariantProps<typeof styles> {
  size?:
    | 'extraSmall'
    | 'mxExtraSmall'
    | 'small'
    | 'medium'
    | 'mlarge'
    | 'large';
  user?: Pick<PublicUserDocument, 'username' | 'photo' | 'is_verified'> | null;
  showNotificationCount?: boolean;
  showBadge?: boolean;
  circle?: boolean;
}

// const getUserInitials = (user: UserDocument) => {
//   const names = user.name?.split(' ');
//   if (!names?.length) return user?.email?.[0].toUpperCase() ?? '##';
//   const i = names.map((n) => n[0]);
//   if (i.length === 1) return i[0].toUpperCase();
//   return `${i[0]}${i[i.length - 1]}`.toUpperCase();
// };

const Avatar = ({
  user,
  size,
  showNotificationCount,
  showBadge,
  circle,
}: AvatarProps) => {
  let src, alt, initials;
  if (user) {
    src = user.photo;
    alt = user.username;
    initials = user.username?.slice(0, 2);
  }
  const { total } = useNotifications();
  // Used to get the user's photo if it's not available (seller profile page for non sellers). Only works for logged in users.
  const { userDoc } = useAuth();
  const { data: fallbackDoc } = useQuery({
    queryKey: ['fallbackUserDoc', user?.username],
    queryFn: () => getUserByUsername(user?.username!),
    enabled: !user?.photo && !!user?.username && !!userDoc,
  });
  return (
    <div className="relative">
      <div className={styles({ size })}>
        {src ? (
          <SafeImage
            src={src}
            alt={alt || 'Profile Picture'}
            width={256}
            height={256}
            fill
            key={src}
            fallbackSrc={`https://ui-avatars.com/api/?background=random&length=2&name=${
              initials ?? 'NA'
            }&size=256`}
            className={`${circle && 'rounded-full'}`}
            style={{ objectFit: 'cover' }}
          />
        ) : fallbackDoc ? (
          <SafeImage
            src={fallbackDoc.photo}
            alt={alt || 'Profile Picture'}
            className={`${circle && 'rounded-full'}`}
            width={256}
            height={256}
            fill
            key={fallbackDoc.photo}
            fallbackSrc={`https://ui-avatars.com/api/?background=random&length=2&name=${
              initials ?? 'NA'
            }&size=256`}
            style={{ objectFit: 'cover' }}
          />
        ) : (
          <div className="absolute inset-0 flex items-center justify-center bg-brand-secondary">
            <span className="text-[1.5rem] font-bold uppercase text-brand-white">
              {initials || 'NA'}
            </span>
          </div>
        )}
      </div>
      {showNotificationCount && total > 0 && (
        <>
          {/* red ring after element  */}
          <div className="absolute -right-1 -top-2 flex h-8 w-8 animate-ping items-center justify-center rounded-full border-[.2rem] border-brand-white bg-brand-secondary text-[1rem] font-medium text-brand-white" />

          <div className="absolute -right-1 -top-2 flex h-8 w-8 items-center justify-center rounded-full border-[.2rem] border-brand-white bg-brand-secondary text-[1rem] font-medium text-brand-white">
            {total}
          </div>
        </>
      )}
      {user?.is_verified && showBadge && (
        <div className="absolute  -bottom-[0.8rem] -right-[0.8rem] flex h-[2rem] w-[2rem] items-center justify-center  rounded-full bg-brand-secondary p-1 ">
          <ShieldCheckIcon fill="white" width={20} height={20} />
        </div>
      )}
    </div>
  );
};

export default Avatar;
