import FormLabel from '@c/forms/controls/FormLabel';
import { ChevronLeftIcon, LogoWithoutTextIcon } from '@c/icons';
import AddPaymentMethodModal, {
  PaymentForm,
} from '@c/modals/AddPaymentMethodModal';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Elements } from '@stripe/react-stripe-js';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import LinkTargetBlank from '@ui/Link/LinkTargetBlank';
import { getBalance, getPaymentMethods } from '@util/firestore/payments';
import { STRIPE_ELEMENT_OPTIONS } from '@util/get-stripe';
import { capitalize, formatCurrency, isMobile } from '@util/index';
import { useAuth } from 'context/AuthContext';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { useStripeContext } from 'context/StripeContext';
import { signOut } from 'firebase/auth';
import { PaymentDocument } from 'models/payment';
import Image from 'next/image';
import { useEffect, useState } from 'react';

export type PayClickProps = {
  paymentMethodId: string;
  guestPaymentMethod?: PaymentDocument;
  isRetry?: boolean;
};

type Props = {
  onPreviousClick: () => void;
  onStepComplete: () => void;
};

const Payment = ({ onPreviousClick, onStepComplete }: Props) => {
  const { userDoc, user } = useAuth();
  const { cart, setPaymentMethodId, setGuestPaymentMethod } = useShoppingCart();
  const [addPaymentMethodModalOpen, setAddPaymentMethodModalOpen] =
    useState(false);
  const [error, setError] = useState('');

  const { data: paymentMethodData, isLoading } = useQuery({
    queryKey: ['paymentMethods'],
    queryFn: getPaymentMethods,
  });
  const { stripePromise } = useStripeContext();

  // Set the payment info when the payment methods are loaded if we don't have a payment method selected
  useEffect(() => {
    if (
      paymentMethodData?.length &&
      !cart?.payment_method_id &&
      cart?.payment_type !== 'affirm' &&
      cart?.payment_type !== 'balance'
    ) {
      if (paymentMethodData[0].id) {
        setPaymentMethodId(paymentMethodData[0].id);
      }
    }
  }, [
    cart?.payment_method_id,
    cart?.payment_type,
    paymentMethodData,
    setPaymentMethodId,
  ]);

  const { data: mxlockerBalance, isLoading: isLoadingBalance } = useQuery({
    queryKey: ['balance'],
    queryFn: () => getBalance(),
    enabled: !!userDoc?.account_id,
  });

  const paymentOptions =
    paymentMethodData?.map((paymentMethod) => ({
      id: paymentMethod.id ?? '',
      label: `${capitalize(paymentMethod.brand)} - ${paymentMethod.last4}`,
      value: paymentMethod.id ?? '',
      name: paymentMethod.name,
    })) ?? [];

  if (!cart || !userDoc) return null;

  if (user?.isAnonymous && !paymentOptions.length) {
    return (
      <Elements
        stripe={stripePromise}
        options={{
          ...STRIPE_ELEMENT_OPTIONS,
          paymentMethodCreation: 'manual',
          mode: 'setup',
          currency: 'usd',
        }}
      >
        <PaymentForm
          userDoc={userDoc}
          dismiss={(paymentMethodId, guestPaymentMethod) => {
            if (paymentMethodId) {
              setPaymentMethodId(paymentMethodId);
              if (guestPaymentMethod) {
                setGuestPaymentMethod(guestPaymentMethod);
              }
              onStepComplete();
            }
          }}
        />
      </Elements>
    );
  }

  return (
    <div className="mx-1 my-1 flex flex-col gap-16 px-8 sm:px-0">
      <FormLabel value="Payment method" required>
        <div className="flex flex-col gap-4">
          {userDoc?.account_id && (
            <PaymentMethodCard
              title={'MX Locker Balance'}
              subtitle={'Your balance must be greater than your order total.'}
              onClick={() => {
                if (
                  mxlockerBalance?.available_balance !== undefined &&
                  cart.total > 0
                ) {
                  if (mxlockerBalance.available_balance < cart.total) {
                    setError(
                      'You MX Locker balance is not enough to pay for this order. Please select another payment method.'
                    );
                  } else {
                    setError('');
                    setPaymentMethodId('balance');
                  }
                }
              }}
              mxlockerBalance={mxlockerBalance?.available_balance ?? 0}
              loadingBalance={isLoadingBalance}
              selected={cart.payment_type === 'balance'}
            />
          )}

          {isLoading && (
            <div className="animate-pulse">
              <PaymentMethodCard
                title={'Loading payment methods...'}
                subtitle={'Please wait...'}
                onClick={() => {}}
                selected={false}
              />
            </div>
          )}

          {paymentOptions.map((option) => (
            <CreditCard
              key={option.id}
              label={option.label}
              name={option.name ?? ''}
              selected={
                option.id === cart.payment_method_id &&
                cart.payment_type === 'card'
              }
              onClick={() => {
                setPaymentMethodId(option.id);
                setError('');
              }}
            />
          ))}

          <PaymentMethodCard
            title={'Affirm'}
            subtitle={'Pay for your purchase over time.'}
            onClick={() => {
              setError('');
              setPaymentMethodId('affirm');
            }}
            selected={cart.payment_type === 'affirm'}
          />
        </div>
      </FormLabel>

      {error && <p className="text-red-500">{error}</p>}

      <div className="flex flex-col gap-4">
        <Button
          type="primary"
          text="Add a credit/debit card"
          className="w-full !rounded-[2rem] !bg-brand-lightest-gray !py-12 text-[1.8rem] !font-semibold !text-black sm:rounded-lg sm:py-4"
          onClick={() => {
            setAddPaymentMethodModalOpen(true);
          }}
        />

        <div className="flex flex-col justify-between gap-4 font-semibold sm:flex-row-reverse">
          <Button
            text="Continue"
            type="secondary"
            className="!rounded-[2rem] !py-12 text-[1.8rem] sm:rounded-lg sm:py-4"
            onClick={() => {
              if (!cart.payment_method_id) {
                setError('Please select a payment method');
                return;
              } else {
                onStepComplete();
              }
            }}
          />

          <Button
            leadingIcon={<ChevronLeftIcon />}
            text="Return to shipping"
            type="text"
            width="extraSmall"
            onClick={onPreviousClick}
          />
        </div>
      </div>
      <AddPaymentMethodModal
        isFullScreen={isMobile()}
        isOpen={addPaymentMethodModalOpen}
        dismiss={(id) => {
          setAddPaymentMethodModalOpen(false);
          if (id) {
            setPaymentMethodId(id);
          }
        }}
      />
    </div>
  );
};

export const PaymentMethodCard = ({
  title,
  subtitle,
  mxlockerBalance,
  loadingBalance,
  onClick,
  selected,
}: {
  title: string;
  subtitle: string;
  mxlockerBalance?: number;
  loadingBalance?: boolean;
  onClick: () => void;
  selected?: boolean;
}) => {
  return (
    <button
      className={`flex min-h-[7rem] w-full flex-row items-center gap-[3rem] rounded-[2rem] px-8 py-6 text-start transition-colors sm:min-h-0 sm:flex-col sm:items-start sm:justify-between sm:rounded-2xl sm:p-[2.4rem] ${
        selected
          ? 'border-[1px] border-brand-secondary bg-inherit'
          : 'bg-[#F4f4f4]'
      }`}
      onClick={onClick}
    >
      {title === 'MX Locker Balance' && (
        <div className="flex sm:hidden">
          <LogoWithoutTextIcon />
        </div>
      )}

      <div className="flex w-full justify-between">
        <div className="flex items-center gap-[1.6rem]">
          {title === 'Affirm' && (
            <Image
              src={
                'https://seeklogo.com/images/A/affirm-logo-A10DF1FE39-seeklogo.com.png'
              }
              alt="Affirm"
              className="aspect-video h-8 w-24"
              height={200}
              width={400}
            />
          )}
          {title === 'MX Locker Balance' && (
            <div className="hidden sm:block">
              <LogoWithoutTextIcon />
            </div>
          )}
          <div className="flex flex-col gap-[0.8rem]">
            <div className="flex items-center text-[1.8rem] font-semibold leading-[2.7rem]">
              {title}
            </div>
            <div className="hidden text-[1.3rem] leading-[2rem] sm:block">
              {subtitle}
            </div>
          </div>
        </div>

        {title === 'MX Locker Balance' && (
          <div className="flex items-center gap-[2.4rem]">
            {loadingBalance ? (
              <div className="flex w-full items-center justify-between gap-[2.4rem]">
                <div className="flex items-center text-[1.5rem] font-semibold leading-[2.2rem]">
                  <div className="h-[2.2rem] w-[6rem] animate-pulse rounded-md bg-gray-300" />
                </div>
              </div>
            ) : (
              <>
                <p className="sm:text-secondary items-center text-[1.8rem] font-semibold leading-[2.2rem] text-zinc-500 sm:text-[1.5rem]">
                  {formatCurrency(mxlockerBalance ?? 0)}
                </p>{' '}
              </>
            )}
          </div>
        )}
      </div>
    </button>
  );
};

export const CreditCard = ({
  label,
  selected,
  onClick,
  noBorder,
  name,
  showArrow,
  onClickArrow,
  arrowDirection = 'down',
}: {
  label: string;
  selected: boolean;
  onClick?: () => void;
  name: string;
  noBorder?: boolean;
  showArrow?: boolean;
  onClickArrow?: () => void;
  arrowDirection?: 'up' | 'down';
}) => {
  const handleClick = () => {
    if (!onClick && onClickArrow) {
      onClickArrow();
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <button
      type="button"
      className={`flex w-full flex-row items-center justify-between gap-[3rem] rounded-[2rem] px-8 py-6 text-start transition-colors sm:flex-col sm:items-start sm:rounded-2xl sm:p-[2.4rem] ${
        selected
          ? !noBorder && 'border-[1px] border-brand-secondary bg-inherit'
          : 'bg-[#F4f4f4]'
      }`}
      onClick={handleClick}
    >
      <div className="flex w-full items-center justify-between gap-x-8">
        <div className="flex items-center gap-x-8">
          {label.toLowerCase().includes('visa') && (
            <Image
              src={'/cc_brands/visa.png'}
              alt={'Visa'}
              className="h-16 w-24 object-contain"
              height={24}
              width={24}
            />
          )}

          {label.toLowerCase().includes('amex') && (
            <Image
              src={'/cc_brands/amex.png'}
              alt={'Amex'}
              className="h-16 w-24 object-contain"
              height={24}
              width={24}
            />
          )}

          {label.toLowerCase().includes('mastercard') && (
            <Image
              src={'/cc_brands/mastercard.png'}
              alt={'mastercard'}
              className="h-16 w-24 object-contain"
              height={24}
              width={24}
            />
          )}

          <div className="flex flex-col">
            <h3 className="text-[1.8rem] font-semibold">{name}</h3>

            <span className="flex items-center text-[1.8rem] font-medium leading-[2.7rem] text-zinc-500">
              {label}
            </span>
          </div>
        </div>

        {showArrow && (
          <ChevronDownIcon
            aria-label="arrow down"
            onClick={(e) => {
              e.stopPropagation();
              onClickArrow?.();
            }}
            className="h-10 w-10 text-zinc-600"
            style={{
              transform: arrowDirection === 'up' ? 'rotate(180deg)' : 'none',
              transition: 'transform 0.3s ease',
            }}
          />
        )}
      </div>
    </button>
  );
};

export default Payment;
