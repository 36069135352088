import { CurationsIcon, FlagIcon } from '@c/icons';
import { LogoWithoutText } from '@c/icons/logoWithoutText';
import { ChatDocument } from '@models/chat';
import { useQuery } from '@tanstack/react-query';
import Avatar from '@ui/Avatar';
import Chip from '@ui/Chip';
import SafeImage from '@ui/SafeImage';
import { getOffer } from '@util/firestore/offers';
import { getOrderById } from '@util/firestore/orders';
import { getProductById } from '@util/firestore/products';
import { getPublicUserDoc, getUserById } from '@util/firestore/users';
import { offerStateToText, timeAgo } from '@util/index';
import { useAuth } from 'context/AuthContext';
import { useMemo } from 'react';
import { formatTimeRemaining, Hourglass } from './MessageOffer';

interface MessagePreviewProps {
  onClick: any;
  selected?: boolean;
  chat: ChatDocument;
}

const MessagePreview = ({ selected, onClick, chat }: MessagePreviewProps) => {
  const { userDoc } = useAuth();

  const { data: offer } = useQuery({
    queryKey: ['offer', chat.offer_id],
    queryFn: () => getOffer(chat.offer_id!),
    enabled: !!chat.offer_id,
  });
  const { data: productDoc } = useQuery({
    queryKey: ['product', chat.product_id],
    queryFn: () => getProductById(chat.product_id!),
    enabled: !!chat.product_id,
  });
  const { data: sellerDoc } = useQuery({
    queryKey: ['publicUser', chat.seller_id],
    queryFn: () =>
      getPublicUserDoc({
        uid: chat.seller_id,
        noCache: userDoc?.roles?.support,
      }),
    enabled: !!chat.seller_id,
  });
  const { data: buyerDoc } = useQuery({
    queryKey: ['publicUser', chat.buyer_id],
    queryFn: () =>
      getPublicUserDoc({
        uid: chat.buyer_id,
        noCache: userDoc?.roles?.support,
      }),
    enabled: !!chat.buyer_id,
  });

  const { data: fullBuyerDoc } = useQuery({
    queryKey: ['user', chat.buyer_id],
    queryFn: () => getUserById(chat.buyer_id!),
    enabled: !!chat.buyer_id && !!userDoc?.roles?.support,
  });
  const { data: fullSellerDoc } = useQuery({
    queryKey: ['user', chat.seller_id],
    queryFn: () => getUserById(chat.seller_id!),
    enabled: !!chat.seller_id && !!userDoc?.roles?.support,
  });

  const { data: orderDoc } = useQuery({
    queryKey: ['order', chat.order_id],
    queryFn: () => getOrderById(chat.order_id!),
    enabled: !!chat.order_id,
  });

  const getChipColor = (status: string) => {
    switch (status) {
      case 'accepted':
        return 'success';
      case 'declined':
        return 'error';
      case 'pending':
        return 'warning';
      case 'cancelled':
        return 'gray';
      default:
        return 'gray';
    }
  };

  const getTitle = () => {
    if (chat.case_num) {
      // pad with 0s
      return 'Support #' + chat.case_num.toString().padStart(5, '0');
    }
    if (chat.is_offer) {
      return productDoc?.title;
    } else if (sellerDoc?.uid === userDoc?.uid) {
      return buyerDoc?.username;
    }
    return sellerDoc?.username;
  };

  const isUnread = useMemo(() => {
    return userDoc?.uid
      ? !!chat.unread?.[userDoc.uid] ||
          (chat.is_expert &&
            userDoc.roles?.support &&
            chat.unread[chat.seller_id])
      : false;
  }, [
    chat.is_expert,
    chat.seller_id,
    chat.unread,
    userDoc?.roles?.support,
    userDoc?.uid,
  ]);

  const getMessageImage = () => {
    //  MX Locker icon for support messages
    if (userDoc?.roles?.support) {
      if (chat.case_num) {
        return (
          <Avatar
            size="medium"
            user={
              (sellerDoc?.uid === userDoc.uid ? buyerDoc : sellerDoc) ??
              undefined
            }
            circle
          />
        );
      }
      return (
        <div className="flex gap-x-2">
          {sellerDoc?.uid === userDoc.uid ? (
            <Avatar size="medium" user={buyerDoc ?? undefined} circle />
          ) : (
            <Avatar size="medium" user={sellerDoc ?? undefined} circle />
          )}
        </div>
      );
    }
    if (chat.case_num) {
      return (
        <div className="flex items-center justify-center">
          <LogoWithoutText className="h-[5rem] w-[5rem]" />
        </div>
      );
    }
    // Profile image for regular messages
    if (!chat.is_offer && !chat.product_id && sellerDoc) {
      return <Avatar size="small" user={sellerDoc} circle />;
    }

    // Product image for offers or messages about a product
    return (
      <div className="relative min-h-[5rem] min-w-[5rem] overflow-clip rounded-2xl">
        <SafeImage
          fill
          src={productDoc?.images?.[0]?.thumb ?? ''}
          alt="product pic"
          key={productDoc?.id}
          className="aspect-square rounded-full object-cover"
        />
      </div>
    );
  };

  const getLastText = () => {
    if (!chat.messages || chat.messages.length === 0) return 'No messages';
    const lastMessage = chat.messages[chat.messages.length - 1];
    if (lastMessage.is_img) return 'Image';
    if (lastMessage.uid === userDoc?.uid) {
      return 'You: ' + lastMessage.content;
    }
    return lastMessage.content;
  };

  const getChatTopic = () => {
    if (chat.case_num) {
      return 'Support';
    }
    if (chat.order_id) {
      return `Order: #${orderDoc?.order_num} ${
        !!orderDoc?.ra_fee ? ' - RA Opted In' : ''
      }`;
    }
    if (chat.is_offer) {
      return `Offer: ${offer && offerStateToText(offer?.state)}`;
    }
    if (chat.product_id) {
      return `Product: ${productDoc?.title}`;
    }
  };

  return (
    <div
      className={`relative flex w-full cursor-pointer justify-between gap-[1.6rem] px-[1.6rem] py-[1.6rem] hover:bg-brand-lightest-gray ${
        selected ? 'bg-brand-lightest-gray' : ''
      }`}
      onClick={() => onClick?.()}
    >
      {chat.flagged && (
        <div className="absolute left-2 top-2 text-brand-red">
          <FlagIcon />
        </div>
      )}

      <div className="absolute right-4 top-4">
        {isUnread && (
          <div className="flex h-[2rem] w-[2rem] items-center justify-center rounded-full bg-brand-red font-semibold leading-[1.6rem] text-brand-white">
            1
          </div>
        )}
      </div>

      <div className="flex w-full items-start overflow-hidden">
        {getMessageImage()}

        <div className="ml-5 w-full overflow-hidden">
          {userDoc?.roles?.admin && (
            <>
              {fullSellerDoc?.uid !== userDoc.uid &&
                fullSellerDoc?.metrics.num_sold === 1 &&
                chat.order_id && (
                  <p className="my-2 w-fit overflow-hidden text-ellipsis rounded-full bg-brand-secondary p-2 px-2 text-[1.3rem] text-white lg:whitespace-nowrap">
                    FIRST TIME SELLER
                  </p>
                )}
              {fullBuyerDoc?.uid !== userDoc.uid &&
                fullBuyerDoc?.metrics.num_purchased === 1 &&
                chat.order_id && (
                  <p className="my-2 w-fit overflow-hidden text-ellipsis rounded-full bg-green-800 p-2 px-2 text-[1.3rem] text-white lg:whitespace-nowrap">
                    FIRST TIME BUYER
                  </p>
                )}
              <p className="w-full overflow-hidden text-ellipsis text-[1.3rem] text-brand-gray lg:whitespace-nowrap">
                B: {buyerDoc?.username}
              </p>
              <p className="w-full overflow-hidden text-ellipsis text-[1.3rem] text-brand-gray lg:whitespace-nowrap">
                S: {sellerDoc?.username}
              </p>

              <p className="w-full overflow-hidden text-ellipsis text-[1.3rem] text-brand-gray lg:whitespace-nowrap">
                {getChatTopic()}
              </p>
            </>
          )}

          <h1 className="line-clamp-2 w-full overflow-hidden text-ellipsis text-[1.5rem] font-semibold lg:whitespace-nowrap">
            {getTitle()}{' '}
            {chat.is_expert && (
              <span className="flex items-center gap-2 text-brand-gray">
                Expert <CurationsIcon />
              </span>
            )}
          </h1>

          {offer ? (
            <div className="my-2 flex w-full items-center justify-between">
              <Chip
                text={offerStateToText(offer.state)}
                color={getChipColor(
                  offerStateToText(offer.state).toLowerCase()
                )}
                className="!rounded-full !px-4 !text-[1.3rem] !font-semibold"
              />

              {offer.state === 0 && (
                <div className="flex items-center gap-x-2">
                  <span className={'text-[1.3rem] font-medium text-zinc-700'}>
                    Expires in {formatTimeRemaining(offer)}
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className="my-2 flex w-full items-center justify-between gap-4">
              <span
                className={`my-2 line-clamp-1 w-full text-ellipsis text-[1.3rem] text-brand-gray ${
                  isUnread ? 'font-bold' : ''
                }`}
              >
                {getLastText()}
              </span>
              <span
                className={
                  'whitespace-nowrap text-[1.3rem] font-medium text-zinc-700'
                }
              >
                {timeAgo(chat.last_time)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessagePreview;
