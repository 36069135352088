import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import Button from '@ui/Button';
import { updateUserByKey } from '@util/firestore/users';
import { AddressDocument } from '@models/address';
import { useAuth } from 'context/AuthContext';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import BaseModal from './BaseModal';
import { useStripeContext } from 'context/StripeContext';
import { STRIPE_ELEMENT_OPTIONS } from '@util/get-stripe';
import StripeAddressForm, {
  StripeAddressChangeEvent,
} from '@c/forms/StripeAddressForm';
import { isMobile } from '@util/index';

interface AddressFormProps {
  dismiss: (address?: AddressDocument) => void;
  address?: AddressDocument;
  dontUpdateUserDoc?: boolean;
  skipValidation?: boolean;
}
export const AddressForm = ({
  dismiss,
  address,
  dontUpdateUserDoc,
  skipValidation,
}: AddressFormProps) => {
  const { userDoc, user } = useAuth();
  const queryClient = useQueryClient();
  const elements = useElements();
  const stripe = useStripe();
  const [verifiedAddress, setVerifiedAddress] =
    React.useState<AddressDocument | null>(null);

  const [validating, setValidating] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const handleChange = (e: StripeAddressChangeEvent) => {
    if (e.status === 'validating') {
      setValidating(true);
      return;
    }
    setValidating(false);
    if (e.status === 'valid') {
      setVerifiedAddress(e.result);
    } else {
      setVerifiedAddress(null);
    }
  };

  const handleSubmit = async () => {
    if (!stripe || !elements || !userDoc) return;

    const addressElement = elements.getElement('address');

    if (!addressElement) return;
    if (!verifiedAddress) return;

    verifiedAddress.is_default = address?.is_default ?? false;

    const addresses = userDoc.addresses ?? [];
    setSubmitting(true);
    if (dontUpdateUserDoc) {
      dismiss(verifiedAddress);
      setSubmitting(false);
      return;
    } else {
      if (address) {
        const i = addresses.findIndex(
          (a) => a.address_line1 === address.address_line1
        );
        const existingAddress = addresses[i];
        verifiedAddress.is_default = existingAddress?.is_default ?? false;
        addresses[i] = verifiedAddress;
      } else {
        const existingAddress = addresses.find(
          (a) => a.address_line1 === verifiedAddress.address_line1
        );
        if (!existingAddress) {
          addresses.push(verifiedAddress);
        }
      }
      await updateUserByKey(userDoc.uid, 'addresses', addresses);
      await queryClient.invalidateQueries({
        queryKey: ['authUser', userDoc?.uid],
      });

      dismiss(verifiedAddress);
      setSubmitting(false);
    }
  };

  return (
    <div className="flex max-h-screen flex-col gap-[1.6rem] overflow-y-visible p-[2.4rem] sm:min-w-[40rem]">
      <StripeAddressForm
        mode="shipping"
        defaultValues={address}
        onChange={handleChange}
        skipValidation={skipValidation}
      />
      <div className="flex flex-col justify-end font-semibold text-brand-secondary sm:flex-row">
        <Button
          className="!rounded-[2rem] !py-12  text-[1.8rem] sm:rounded-lg sm:py-4"
          type="text"
          text="Cancel"
          onClick={() => dismiss()}
        />
        <Button
          className="!rounded-[2rem] !py-12  text-[1.8rem] sm:rounded-lg sm:py-4"
          type="secondary"
          text={user?.isAnonymous ? 'Continue' : 'Save Address'}
          onClick={handleSubmit}
          loading={validating || submitting}
          disabled={verifiedAddress === null || validating}
        />
      </div>
    </div>
  );
};

type AddAddressModalProps = {
  isOpen: boolean;
  dismiss: (address?: AddressDocument) => void;
  address?: AddressDocument;
  dontUpdateUserDoc?: boolean;
  skipValidation?: boolean;
};

const AddAddressModal = ({
  isOpen,
  dismiss,
  address,
  dontUpdateUserDoc,
  skipValidation,
}: AddAddressModalProps) => {
  const { stripePromise } = useStripeContext();
  return (
    <BaseModal
      isOpen={isOpen}
      dismiss={() => dismiss()}
      title={
        <span className="p-[2.4rem] text-[2.4rem] font-semibold sm:p-0">
          {address ? 'Edit Address' : 'Add New Address'}
        </span>
      }
      isFullScreen={isMobile()}
      overflowType="visible"
    >
      <Elements stripe={stripePromise} options={STRIPE_ELEMENT_OPTIONS}>
        <AddressForm
          address={address}
          dismiss={(address) => dismiss(address)}
          dontUpdateUserDoc={dontUpdateUserDoc}
          skipValidation={skipValidation}
        />
      </Elements>
    </BaseModal>
  );
};

export default AddAddressModal;
