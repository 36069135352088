import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
  getTwentyChats,
  getTwentySellerChats,
  getTwentyBuyerChats,
  getTwentyFlaggedChats,
  getTotalAdminBuyerChats,
  getTotalAdminSellerChats,
} from '@util/firestore/messages';
import { useAuth } from 'context/AuthContext';

export const useAdminChats = ({
  variant,
}: {
  variant: 'messages' | 'offers' | 'orderDetail' | 'experts';
}) => {
  const { userDoc } = useAuth();
  const {
    data: adminChats,
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['adminChats'],
    enabled: !!userDoc?.roles?.admin,
    queryFn: ({ pageParam }) => getTwentyChats(pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.length < 20) return undefined;
      return lastPage[lastPage.length - 1].last_time;
    },
    initialPageParam: 0,
  });
  const { data: totalAdminBuyerChats } = useQuery({
    queryKey: ['totalAdminBuyerChats'],
    queryFn: () => getTotalAdminBuyerChats(),
    enabled: !!userDoc?.roles?.admin,
  });
  const {
    data: adminSellerChats,
    isLoading: isLoadingSellerChats,
    isFetching: isFetchingSellerChats,
    isFetchingNextPage: isFetchingNextPageSellerChats,
    fetchNextPage: fetchNextPageSellerChats,
    hasNextPage: hasNextPageSellerChats,
    refetch: refetchSellerChats,
  } = useInfiniteQuery({
    queryKey: ['adminSellerChats'],
    enabled: !!userDoc?.roles?.admin && variant === 'messages',
    queryFn: ({ pageParam }) => getTwentySellerChats(pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.length < 20) return undefined;
      return lastPage[lastPage.length - 1].last_time;
    },
    initialPageParam: 0,
  });
  const { data: totalAdminSellerChats } = useQuery({
    queryKey: ['totalAdminSellerChats'],
    queryFn: () => getTotalAdminSellerChats(),
    enabled: !!userDoc?.roles?.admin,
  });

  const {
    data: adminBuyerChats,
    isLoading: isLoadingBuyerChats,
    isFetching: isFetchingBuyerChats,
    isFetchingNextPage: isFetchingNextPageBuyerChats,
    fetchNextPage: fetchNextPageBuyerChats,
    hasNextPage: hasNextPageBuyerChats,
    refetch: refetchBuyerChats,
  } = useInfiniteQuery({
    queryKey: ['adminBuyerChats'],
    enabled: !!userDoc?.roles?.admin && variant === 'messages',
    queryFn: ({ pageParam }) => getTwentyBuyerChats(pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.length < 20) return undefined;
      return lastPage[lastPage.length - 1].last_time;
    },
    initialPageParam: 0,
  });
  const {
    data: adminFlaggedChats,
    isLoading: isLoadingFlaggedChats,
    isFetching: isFetchingFlaggedChats,
    isFetchingNextPage: isFetchingNextPageFlaggedChats,
    fetchNextPage: fetchNextPageFlaggedChats,
    hasNextPage: hasNextPageFlaggedChats,
    refetch: refetchFlaggedChats,
  } = useInfiniteQuery({
    queryKey: ['adminFlaggedChats'],
    enabled: !!userDoc?.roles?.admin && variant === 'messages',
    queryFn: ({ pageParam }) => getTwentyFlaggedChats(pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.length < 20) return undefined;
      return lastPage[lastPage.length - 1].last_time;
    },
    initialPageParam: 0,
  });

  function onScrollSeller(ev: React.UIEvent<HTMLDivElement, UIEvent>) {
    if (
      isLoadingSellerChats ||
      isFetchingNextPageSellerChats ||
      !hasNextPageSellerChats
    )
      return;
    const el = ev.target as HTMLDivElement;

    const threshold = el.clientHeight * 0.6;
    const thresholdReached =
      el.scrollTop + el.clientHeight >= el.scrollHeight - threshold;
    if (thresholdReached) fetchNextPageSellerChats();
  }
  function onScrollBuyer(ev: React.UIEvent<HTMLDivElement, UIEvent>) {
    if (
      isLoadingBuyerChats ||
      isFetchingNextPageBuyerChats ||
      !hasNextPageBuyerChats
    )
      return;
    const el = ev.target as HTMLDivElement;

    const threshold = el.clientHeight * 0.6;
    const thresholdReached =
      el.scrollTop + el.clientHeight >= el.scrollHeight - threshold;
    if (thresholdReached) fetchNextPageBuyerChats();
  }
  function onScrollAll(ev: React.UIEvent<HTMLDivElement, UIEvent>) {
    if (isLoading || isFetchingNextPage || !hasNextPage) return;
    const el = ev.target as HTMLDivElement;

    const threshold = el.clientHeight * 0.6;
    const thresholdReached =
      el.scrollTop + el.clientHeight >= el.scrollHeight - threshold;
    if (thresholdReached) fetchNextPage();
  }
  function onScrollFlagged(ev: React.UIEvent<HTMLDivElement, UIEvent>) {
    if (
      isLoadingFlaggedChats ||
      isFetchingFlaggedChats ||
      !hasNextPageFlaggedChats
    )
      return;
    const el = ev.target as HTMLDivElement;

    const threshold = el.clientHeight * 0.6;
    const thresholdReached =
      el.scrollTop + el.clientHeight >= el.scrollHeight - threshold;
    if (thresholdReached) fetchNextPageFlaggedChats();
  }

  return {
    adminChats,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch,
    adminSellerChats,
    isLoadingSellerChats,
    isFetchingSellerChats,
    isFetchingNextPageSellerChats,
    refetchSellerChats,
    adminBuyerChats,
    isLoadingBuyerChats,
    isFetchingBuyerChats,
    isFetchingNextPageBuyerChats,
    refetchBuyerChats,
    onScrollAll,
    onScrollBuyer,
    onScrollSeller,
    fetchNextPage,
    hasNextPage,
    hasNextPageBuyerChats,
    hasNextPageSellerChats,
    fetchNextPageSellerChats,
    fetchNextPageBuyerChats,
    adminFlaggedChats,
    isLoadingFlaggedChats,
    isFetchingFlaggedChats,
    isFetchingNextPageFlaggedChats,
    fetchNextPageFlaggedChats,
    hasNextPageFlaggedChats,
    refetchFlaggedChats,
    onScrollFlagged,
    totalAdminBuyerChats,
    totalAdminSellerChats,
  };
};
