import { useEffect, useRef } from 'react';

const useLogWhenInView = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let observer: IntersectionObserver;
    const element = ref.current;

    if (element) {
      observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            callback();
            // Send event to Google Analytics
          }
        },
        {
          threshold: 0.1,
        }
      );

      observer.observe(element);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ref;
};

export default useLogWhenInView;
