import { ChatDocument } from '@models/chat';
import { onSnapshot } from 'firebase/firestore';
import { useState, useMemo, useEffect } from 'react';
import { getChatsByUidQuery } from '@util/firestore/messages/';

const useRealtimeChats = (
  userid?: string,
  variant?: 'messages' | 'offers' | 'orderDetail' | 'experts'
) => {
  const [realtimeChats, setChats] = useState<ChatDocument[] | undefined>(
    undefined
  );

  const chatsQuery = useMemo(
    () => (!userid ? null : getChatsByUidQuery(userid, variant)),
    [userid]
  );

  useEffect(() => {
    if (!chatsQuery) return;
    const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
      const realTimeData = snapshot.docs.map((doc) => doc.data());
      setChats(realTimeData);
    });
    return () => {
      unsubscribe();
    };
  }, [chatsQuery]);

  return {
    realtimeChats,
  };
};

export default useRealtimeChats;
