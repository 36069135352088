import { ArrowUpRightIcon, ChatFeatureIcon, SaleFeatureIcon } from '@c/icons';
import MobileChatModal from '@c/modals/MobileChatModal';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import SafeImage from '@ui/SafeImage';
import { getChatById, markAsRead } from '@util/firestore/messages';
import { getOrderById } from '@util/firestore/orders';
import { getProductsByIds } from '@util/firestore/products';
import { isTablet } from '@util/index';
import { ChatDocument, ChatMessageDocument } from '@models/chat';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React, { useEffect, useMemo, useState } from 'react';
import ConversationList, { ChatBubbleIcon } from './ConversationList';
import MessageOffer from './MessageOffer';
import MessageWindow from './MessageWindow';
import ProductPreview from './messages/previews/ProductPreview';
import MobileMessageOffer from './MobileMessageOffer';
import { onSnapshot } from 'firebase/firestore';

export const OrderPreview = ({
  orderId,
  uids,
}: {
  orderId: string;
  uids?: string[];
}) => {
  const { data: order, isLoading: isLoadingOrder } = useQuery({
    queryKey: ['order', orderId],
    queryFn: () => getOrderById(orderId),
    enabled: !!orderId,
  });
  const { setChatOpen } = useChat();
  const { userDoc } = useAuth();

  const { data: products } = useQuery({
    queryKey: ['products', order?.product_ids],
    queryFn: () => getProductsByIds(order?.product_ids ?? []),
    enabled: !!order?.product_ids,
  });
  if (isLoadingOrder) {
    return (
      <div className="mx-auto my-4 h-[3rem] w-11/12 animate-pulse rounded-2xl bg-brand-gray" />
    );
  }
  if (!order) {
    return <div>Order not found</div>;
  }
  const getHref = () => {
    if (
      userDoc &&
      (userDoc.uid === order.buyer_id || userDoc?.roles?.support)
    ) {
      return `/dashboard/purchases/${order.id}`;
    }
    return `/dashboard/orders/${order.id}`;
  };

  return (
    <>
      {/* Desktop */}
      <div className="hidden h-full min-w-[40rem] gap-[1.2rem] bg-brand-lightest-gray px-[1.6rem] py-[1.2rem] @4xl:flex">
        <div className="flex w-full flex-col gap-[1.6rem] ">
          <Link href={getHref()} className="text-[2.4rem] font-medium ">
            Order #{order.order_num}
          </Link>
          <div className="flex flex-col gap-[1.6rem]">
            {products?.map((product) => {
              if (
                uids &&
                !uids.includes(product.seller_id) &&
                !userDoc?.roles?.support
              )
                return null;
              return (
                <div key={product.id} className="flex gap-[1.6rem]">
                  <SafeImage
                    src={product.thumbnail}
                    alt="product image"
                    className="aspect-square"
                    height={80}
                    width={80}
                  />
                  <div className="flex flex-col justify-center">
                    <span className="text-[1.8rem] font-semibold">
                      {product.title}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          {!!order.ra_fee && userDoc?.roles?.support && (
            <p className="text-[1.6rem] font-semibold">
              RA Fee: ${order.ra_fee}
            </p>
          )}

          <Button
            leadingIcon={<ArrowUpRightIcon />}
            onClick={() => setChatOpen(false)}
            text="View Order"
            href={getHref()}
          />
        </div>
      </div>
      {/* Mobile */}
      <div className="lg flex items-center justify-between gap-[1.2rem] bg-brand-lightest-gray px-[1.6rem] py-[1.2rem] @4xl:hidden">
        <div className="flex flex-col gap-[0.8rem]">
          <Link href={getHref()} className="font-medium text-brand-gray">
            Order #{order.order_num}
          </Link>
          {order.product_ids && (
            <p className="text-[1.4rem] text-brand-gray">
              {order.product_ids.length} item(s)
            </p>
          )}
          {!!order.ra_fee && userDoc?.roles?.support && (
            <p className="text-[1.6rem] font-semibold">
              RA Fee: ${order.ra_fee}
            </p>
          )}
        </div>
        <Button
          type="text"
          leadingIcon={<ArrowUpRightIcon />}
          width="extraSmall"
          height="small"
          onClick={() => setChatOpen(false)}
          href={getHref()}
        />
      </div>
    </>
  );
};

const getEmptyState = (
  variant: 'messages' | 'offers' | 'orderDetail' | 'experts'
) => {
  if (variant === 'offers') {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-[1.6rem] text-center">
        <SaleFeatureIcon />
        <p className="font-medium text-brand-gray">
          Select an offer to view the details
        </p>
      </div>
    );
  }
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-[1.6rem] text-center">
      <ChatBubbleIcon className="h-20 w-20" />
      <p className="font-medium text-brand-gray">
        Select chat to view the conversation
      </p>
    </div>
  );
};

interface MessageCenterProps {
  chats: ChatDocument[];
  variant: 'messages' | 'offers' | 'orderDetail' | 'experts';
  isShared?: boolean;
}
export default function MessageCenter({
  chats,
  variant,
  isShared,
}: MessageCenterProps) {
  const { user } = useAuth();
  const router = useRouter();

  const [selectedChat, setSelectedChat] = React.useState<
    ChatDocument | undefined
  >(isShared ? chats[0] : undefined);
  const [messages, setMessages] = useState<ChatMessageDocument[]>([]);
  const chatsQuery = useMemo(
    () => (selectedChat?.id ? getChatById(selectedChat.id) : null),
    [selectedChat]
  );

  useEffect(() => {
    if (!chatsQuery) return;
    const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
      const data = snapshot.data();
      setMessages(data?.messages ?? []);
    });
    return () => {
      unsubscribe();
    };
  }, [chatsQuery]);
  const updateSelectedChat = (chat: ChatDocument) => {
    setSelectedChat(chat);
    if (chat?.id && user?.uid && chat.unread?.[user.uid]) {
      markAsRead(chat.id, user.uid);
    }
  };
  let latestOfferIndex = 0;
  for (let i = messages.length - 1; i >= 0; i--) {
    if (
      messages[i].offer_amount ||
      messages[i].content.startsWith('New Offer: ')
    ) {
      latestOfferIndex = i;
      break;
    }
  }

  return (
    <>
      {/* Desktop */}
      <div className=" hidden h-full min-h-0  w-full grow !overflow-hidden rounded-xl border-[1px] bg-brand-white lg:flex">
        {!isShared && (
          <div className="w-full grow lg:max-w-[39rem]">
            <ConversationList
              conversations={chats}
              onConversationSelected={updateSelectedChat}
              selectedChat={selectedChat}
              variant={variant}
            />
          </div>
        )}
        <div className="w-full grow">
          {selectedChat ? (
            <MessageWindow
              chat={selectedChat}
              key={selectedChat.id}
              previewSlot={
                selectedChat.offer_id ? (
                  <MessageOffer
                    offer_id={selectedChat.offer_id}
                    isSender={messages[latestOfferIndex]?.uid === user?.uid}
                  />
                ) : selectedChat.order_id ? (
                  <OrderPreview
                    orderId={selectedChat.order_id}
                    uids={selectedChat.uids}
                  ></OrderPreview>
                ) : selectedChat.product_id ? (
                  <ProductPreview productId={selectedChat.product_id} />
                ) : null
              }
              goBack={() => {
                variant === 'offers'
                  ? router.push('/dashboard/offers')
                  : router.push('/dashboard/messages');
              }}
            />
          ) : (
            getEmptyState(variant)
          )}
        </div>
      </div>
      {/* Mobile */}
      <div className="h-full overflow-hidden rounded-xl bg-brand-white lg:hidden">
        {selectedChat && (
          <MobileChatModal
            isOpen={selectedChat && isTablet() ? true : false}
            goBack={async () => {
              variant === 'offers'
                ? router.push('/dashboard/offers')
                : router.push('/dashboard/messages');
              // Using a set timeout to give the router time to update the url, hacky but works
              setTimeout(() => {
                setSelectedChat(undefined);
              }, 500);
            }}
            chat={selectedChat}
            previewSlot={
              variant === 'offers' && selectedChat.offer_id ? (
                <MobileMessageOffer
                  offer_id={selectedChat.offer_id}
                ></MobileMessageOffer>
              ) : selectedChat.order_id ? (
                <OrderPreview
                  orderId={selectedChat.order_id}
                  uids={selectedChat.uids}
                ></OrderPreview>
              ) : selectedChat.product_id ? (
                <ProductPreview productId={selectedChat.product_id} />
              ) : null
            }
          />
        )}
        <div className={`${selectedChat ? 'hidden' : 'h-full'}`}>
          <ConversationList
            conversations={chats}
            onConversationSelected={(uid) => updateSelectedChat(uid)}
            selectedChat={selectedChat}
            variant={variant}
          />
        </div>
      </div>
    </>
  );
}
