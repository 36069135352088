'use client';

import SafeImage from '@ui/SafeImage';
import { ProductDocument } from 'models/product';
import { useState } from 'react';
import { ChevronLeft, ChevronRight, HeartIcon, PlayIcon } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from 'context/AuthContext';
import { handleLikeClick } from '@ui/ProductCarousel';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';

type QuickViewMediaCarouselProps = {
  product: ProductDocument;
};

export default function QuickViewMediaCarousel({
  product,
}: QuickViewMediaCarouselProps) {
  const productVideo = product.video;
  const productMedia = productVideo
    ? [productVideo, ...product.images]
    : product.images;

  const [selectedIndex, setSelectedIndex] = useState(0);
  const selectedMedia = productMedia[selectedIndex];
  const { userDoc } = useAuth();
  const [isLiked, setIsLiked] = useState(
    userDoc?.favorites.includes(product.id)
  );
  const queryClient = useQueryClient();
  const router = useRouter();

  const handlePrevious = () => {
    setSelectedIndex((prev) =>
      prev === 0 ? productMedia.length - 1 : prev - 1
    );
  };

  const handleNext = () => {
    setSelectedIndex((prev) =>
      prev === productMedia.length - 1 ? 0 : prev + 1
    );
  };

  const isVideo = selectedIndex === 0 && productVideo;

  return (
    <div className="flex flex-col gap-y-8">
      <div className="relative">
        <div className="relative h-[55.5rem] w-[55rem]">
          {isVideo ? (
            <video
              autoPlay
              loop
              muted
              playsInline
              className="absolute inset-0 h-full w-full rounded-[1.5rem] object-cover"
              draggable={false}
              src={selectedMedia.full}
            />
          ) : (
            <SafeImage
              src={selectedMedia.thumb}
              alt={product.title}
              width={1000}
              height={1000}
              className="absolute inset-0 h-full w-full rounded-[1.5rem] object-cover"
              key={`image-${selectedMedia.thumb}`}
              fallbackSrc={selectedMedia.download_url}
              loadingClassName="!h-full !w-full"
            />
          )}
        </div>

        {productMedia.length > 1 && (
          <>
            <button
              onClick={handlePrevious}
              className="absolute left-4 top-1/2 z-20 -translate-y-1/2 rounded-full border border-zinc-200 bg-white p-2"
            >
              <ChevronLeft className="h-12 w-12" />
            </button>

            <button
              onClick={handleNext}
              className="order absolute right-4 top-1/2 z-20 -translate-y-1/2 rounded-full border border-zinc-200 bg-white p-2"
            >
              <ChevronRight className="h-12 w-12" />
            </button>
          </>
        )}

        {product.favorite_count > 0 && (
          <div className="absolute inset-0 flex items-start justify-start">
            <div className="ml-6 mt-6 h-[3rem] rounded-full bg-brand-secondary px-4">
              <div className="flex h-full items-center justify-center">
                <div className="whitespace-nowrap text-center text-[1.4rem] font-semibold uppercase text-brand-white sm:text-[2rem]">
                  {product.favorite_count}{' '}
                  {product.favorite_count === 1 ? 'person has' : 'people have'}{' '}
                  this in their cart
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="absolute bottom-4 right-4 z-10 cursor-pointer">
          <motion.button
            className={`flex h-[4rem] w-[4rem] items-center justify-center rounded-full ${
              !isLiked ? 'bg-white' : 'bg-brand-secondary'
            } p-3`}
            type="button"
            whileTap={{ scale: 0.8 }}
            animate={{
              scale: [1, 1.2, 1],
              transition: {
                duration: 0.3,
                times: [0, 0.5, 1],
                ease: 'easeInOut',
              },
            }}
            aria-label="Like product"
            onClick={(e) => {
              if (userDoc) {
                handleLikeClick(
                  e,
                  userDoc,
                  product.id,
                  setIsLiked,
                  isLiked ?? false,
                  queryClient
                );
              } else {
                router.push('/login');
              }
            }}
          >
            <HeartIcon
              className={`h-full w-full ${
                isLiked ? '!fill-white text-white' : 'text-black'
              }`}
              aria-label="Like product"
            />
          </motion.button>
        </div>
      </div>

      <div className="flex w-[50rem] flex-wrap items-center gap-4 overflow-y-hidden">
        {productMedia.map((media, index) => {
          const isSelected = index === selectedIndex;
          const isVideoThumb = index === 0 && productVideo;

          return (
            <div className="h-[8rem] w-[8rem]">
              <div className="relative h-[8rem] w-[8rem]">
                {isVideoThumb ? (
                  <button
                    type="button"
                    className="relative"
                    onClick={() => setSelectedIndex(index)}
                  >
                    <div className="absolute inset-0 z-10 flex items-center justify-center">
                      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-black/50">
                        <PlayIcon className="h-6 w-6 text-white" />
                      </div>
                    </div>

                    <SafeImage
                      src={media.thumb}
                      alt={product.title}
                      width={100}
                      height={100}
                      className={`h-[8rem] w-[8rem] cursor-pointer rounded-[1rem] object-cover transition-opacity ${
                        isSelected ? 'opacity-100' : 'opacity-50'
                      }`}
                      key={`image-${media.thumb}`}
                      fallbackSrc={media.download_url}
                      loadingClassName="!h-[8rem] !w-[8rem] !absolute !inset-0"
                      skipOptimize={true}
                    />
                  </button>
                ) : (
                  <SafeImage
                    src={media.thumb}
                    alt={product.title}
                    width={100}
                    height={100}
                    className={`h-[8rem] w-[8rem] cursor-pointer rounded-[1rem] object-cover transition-opacity ${
                      isSelected ? 'opacity-100' : 'opacity-50'
                    }`}
                    key={`image-${media.thumb}`}
                    fallbackSrc={media.download_url}
                    onClick={() => setSelectedIndex(index)}
                    loadingClassName="!h-[8rem] !w-[8rem] !absolute !inset-0"
                    skipOptimize={true}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
