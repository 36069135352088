import { getExpertChatsQuery } from '@util/firestore/messages';
import { useAuth } from 'context/AuthContext';
import { onSnapshot } from 'firebase/firestore';
import { ChatDocument } from 'models/chat';
import { useEffect, useMemo, useState } from 'react';

export const useExpertChat = () => {
  const { userDoc } = useAuth();
  const [realtimeChats, setChats] = useState<ChatDocument[] | undefined>(
    undefined
  );
  const chatsQuery = useMemo(
    () => (!userDoc?.uid ? null : getExpertChatsQuery(userDoc?.uid)),
    [userDoc?.uid]
  );

  useEffect(() => {
    if (!chatsQuery) return;
    const unsubscribe = onSnapshot(chatsQuery, (snapshot) => {
      const realTimeData = snapshot.docs.map((doc) => doc.data());
      setChats(realTimeData);
    });
    return () => {
      unsubscribe();
    };
  }, [chatsQuery]);

  function getUnreadCount() {
    return (
      realtimeChats?.filter(
        (chat) =>
          chat.unread[userDoc?.uid ?? ''] ||
          (userDoc?.roles?.admin && chat.unread[chat.seller_id])
      ).length ?? 0
    );
  }

  return { getUnreadCount, realtimeChats };
};
