export default function ShippingLocationIcon({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.1896 7.73748C15.1183 7.73562 14.0705 8.05158 13.1789 8.64541C12.2872 9.23923 11.5917 10.0842 11.1805 11.0734C10.7692 12.0627 10.6606 13.1516 10.8685 14.2026C11.0764 15.2535 11.5913 16.2192 12.3482 16.9774C13.105 17.7356 14.0698 18.2522 15.1204 18.4619C16.171 18.6716 17.2601 18.5649 18.2501 18.1553C19.24 17.7458 20.0862 17.0518 20.6815 16.1612C21.2769 15.2706 21.5947 14.2233 21.5947 13.152C21.5947 11.7166 21.0247 10.3398 20.01 9.32447C18.9952 8.30913 17.6188 7.73832 16.1833 7.73748M23.3619 12.8319C23.3619 16.5546 20.1885 23.4193 16.8362 27.2079C16.754 27.2998 16.6534 27.3733 16.5409 27.4237C16.4284 27.474 16.3066 27.5 16.1833 27.5C16.0601 27.5 15.9382 27.474 15.8257 27.4237C15.7132 27.3733 15.6126 27.2998 15.5304 27.2079C12.1499 23.3973 9.00157 16.5577 9.00157 12.8319C8.98159 11.8763 9.15253 10.9264 9.5044 10.0378C9.85626 9.14914 10.382 8.33969 11.0507 7.65685C11.7194 6.974 12.5177 6.43149 13.3988 6.06112C14.2798 5.69076 15.226 5.5 16.1817 5.5C17.1375 5.5 18.0837 5.69076 18.9647 6.06112C19.8458 6.43149 20.6441 6.974 21.3128 7.65685C21.9815 8.33969 22.5072 9.14914 22.8591 10.0378C23.211 10.9264 23.3819 11.8763 23.3619 12.8319Z"
        fill="#C41719"
      />
      <path
        d="M16.1892 7.73748C15.1179 7.73562 14.0701 8.05158 13.1784 8.64541C12.2868 9.23923 11.5913 10.0842 11.18 11.0734C10.7688 12.0627 10.6602 13.1516 10.8681 14.2026C11.0759 15.2535 11.5909 16.2192 12.3477 16.9774C13.1046 17.7356 14.0694 18.2522 15.12 18.4619C16.1705 18.6716 17.2597 18.5649 18.2496 18.1553C19.2396 17.7458 20.0857 17.0518 20.6811 16.1612C21.2765 15.2706 21.5943 14.2233 21.5943 13.152C21.5943 11.7166 21.0243 10.3398 20.0095 9.32447C18.9948 8.30913 17.6184 7.73832 16.1829 7.73748H16.1892ZM23.3678 12.8319C23.3678 16.5546 20.1944 23.4193 16.842 27.2079C16.7599 27.2998 16.6593 27.3733 16.5468 27.4237C16.4343 27.474 16.3124 27.5 16.1892 27.5C16.0659 27.5 15.944 27.474 15.8315 27.4237C15.719 27.3733 15.6184 27.2998 15.5363 27.2079C12.1557 23.3973 9.00743 16.5577 9.00743 12.8319C8.98745 11.8763 9.15839 10.9264 9.51025 10.0378C9.86212 9.14914 10.3878 8.33969 11.0565 7.65685C11.7253 6.974 12.5235 6.43149 13.4046 6.06112C14.2857 5.69076 15.2319 5.5 16.1876 5.5C17.1434 5.5 18.0895 5.69076 18.9706 6.06112C19.8516 6.43149 20.6499 6.974 21.3187 7.65685C21.9874 8.33969 22.5131 9.14914 22.865 10.0378C23.2168 10.9264 23.3878 11.8763 23.3678 12.8319V12.8319Z"
        stroke="black"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
      <path
        d="M13.293 12.8163L15.4651 14.8346L19.0842 11.4697"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
