import FormCurrencyInputFancy from '@c/forms/controls/FormCurrencyInputFancy';
import FormLabel from '@c/forms/controls/FormLabel';
import FormSelect from '@c/forms/controls/FormSelect';
import { BProcess, ProcessStep } from '@c/process/BaseProcess';
import { AddressDocument } from '@models/address';
import { BidDocument } from '@models/bid';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import SafeImage from '@ui/SafeImage';
import { calculateShipping, getShippingRegion } from '@util/firestore/cart';
import { Rate } from '@util/firestore/shipengine';
import { capitalize, formatCurrency } from '@util/index';
import { useAuth } from 'context/AuthContext';
import { ProductDocument, Variation } from 'models/product';
import { Fragment, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useToastContext } from 'context/ToastContext';
import { Montserrat } from 'next/font/google';
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { ChevronLeftIcon, CreditCardIcon } from 'lucide-react';
import ItemShippingSelect from '@c/checkout/ItemShippingSelect';
import { FedExIcon, UPSIcon, USPSIcon } from '@c/icons';
import ShippingLocationIcon from '@c/checkout/assets/shipping-location-ison';
import Image from 'next/image';
import AddAddressModal from './AddAddressModal';
import AddPaymentMethodModal from './AddPaymentMethodModal';
import { useCreateOffer } from '@util/hooks/useCreateOffer';
import { UPS } from '@c/icons/carriers';

const montserrat = Montserrat({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
});

type MobileMakeAnOfferModalProps = {
  show: boolean;
  dismiss: (openDrawer?: boolean) => void;
  product: ProductDocument;
  currentUserBid?: BidDocument;
  highestBid?: BidDocument;
  bidInput?: string;
  selectedVariations?: Variation[] | null;
  isEditingBid?: boolean;
};

export default function MobileMakeAnOfferModal({
  show,
  dismiss,
  product,
  currentUserBid,
  highestBid,
  bidInput,
  selectedVariations,
  isEditingBid,
}: MobileMakeAnOfferModalProps) {
  const { userDoc } = useAuth();
  const [openModal, setOpenModal] = useState<'address' | 'payment' | ''>('');
  const [ratesError, setRatesError] = useState<Error | null>(null);
  const [ratesLoading, setRatesLoading] = useState(true);
  const { showErrorToast } = useToastContext();

  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    errors,
    clearErrors,
    control,
    loadingSubmit,
    selectedRate,
    setSelectedRate,
    getPricePercentageOff,
    flatRateList,
    dynamicList,
    shippingCost,
    setShippingCost,
    paymentMethodData,
    addressOptions,
    calcTax,
    tax,
    updateBid,
    updateShipping,
    currentStep,
    setCurrentStep,
    onSubmit,
    reviewShipping,
    onAddNewAddress,
    onAddNewPaymentMethod,
    checkboxError,
    setCheckboxError,
    checkErrors,
  } = useCreateOffer({
    bidInput,
    product,
    highestBid,
    currentUserBid,
    userDoc,
    dismiss,
    variant: 'offer',
  });

  const paymentOptions = paymentMethodData?.map((paymentMethod) => ({
    id: paymentMethod.id ?? '',
    label: `${capitalize(paymentMethod.brand)} - ${paymentMethod.last4}`,
    value: paymentMethod.id ?? '',
  }));

  const form = watch();

  useEffect(() => {
    updateShipping();
    calcTax();

    if (paymentMethodData?.length && !form.paymentMethodId) {
      const mostRecent = paymentMethodData[paymentMethodData.length - 1];
      setValue('paymentMethodId', mostRecent.id!);
    }
  }, [
    userDoc,
    paymentMethodData,
    product,
    setValue,
    form.shippingAddress,
    calcTax,
  ]);

  useEffect(() => {
    if (!form.shippingAddress && addressOptions.length) {
      setValue('shippingAddress', addressOptions[0].value);

      const selectedAddress = JSON.parse(addressOptions[0].value);
      const shipping = calculateShipping(
        product,
        getShippingRegion(selectedAddress)
      );
      setShippingCost(shipping?.cost ?? 0);

      clearErrors('shippingAddress');
    }
  }, [addressOptions, form.shippingAddress, setValue, product, clearErrors]);

  const OfferShippingOptions = ({
    product,
    shipTo,
    setShippingCost,
    offer_amount,
    selectedRate,
    setSelectedRate,
    ratesError,
    setRatesError,
  }: {
    product: ProductDocument;
    shipTo: AddressDocument;
    setShippingCost: (cost: number) => void;
    offer_amount: number;
    selectedRate: Rate | null;
    setSelectedRate: (rate: Rate | null) => void;
    ratesError: Error | null;
    setRatesError: (error: Error | null) => void;
  }) => {
    useEffect(() => {
      setShippingCost(selectedRate?.total_amount || 0);
    }, [selectedRate?.total_amount, setShippingCost]);

    return (
      <div className="flex flex-col gap-[1.6rem]">
        {ratesError && (
          <div className="flex w-full flex-col gap-4">
            <span className="text-[1.4rem] font-semibold text-brand-red">
              {ratesError.message}
            </span>
          </div>
        )}

        <ItemShippingSelect
          chooseCheapestByDefault
          hideProduct
          product={product}
          shipment={product.shipment}
          shipTo={shipTo}
          hide={false}
          offer_amount={offer_amount}
          selectedRateId={selectedRate?.rate_id ?? null}
          onOptionSelected={({ rate }) => {
            setSelectedRate(rate);
          }}
          onError={(error) => {
            setRatesError(error);
          }}
          onRatesLoaded={() => {
            setRatesLoading(false);
          }}
        />
      </div>
    );
  };

  function handleOfferClick(price: number) {
    setValue('price', Number(price.toFixed(2)));
  }

  const sizeVariation = selectedVariations?.find((v) => v.size !== '');

  const supportedCardBrands = ['visa', 'mastercard', 'amex'];

  const steps: ProcessStep<any>[] = [
    {
      id: 0,
      content: (
        <div className="flex h-[66rem] max-w-[44rem] flex-col justify-between gap-[2.4rem]">
          <div className="flex flex-col gap-[2.4rem]">
            <span className="text-[2.1rem] font-semibold text-black">
              Offer Price
            </span>

            <div className="flex items-center justify-between">
              <div className="flex max-w-[30rem] items-center gap-[1.6rem]">
                <SafeImage
                  className="h-[6rem] w-[6rem] shrink-0 rounded-2xl object-cover"
                  alt={'product image'}
                  src={product.thumbnail}
                />

                <div className="flex flex-col gap-[0.2rem]">
                  <div className="flex flex-col gap-y-2">
                    <span className="w-[22rem] truncate text-[1.7rem] font-semibold text-black">
                      {product.title}
                    </span>

                    <span className="text-[1.6rem] font-medium text-[#7F7F7F]">
                      {product.category}{' '}
                      {product.variations?.length && (
                        <span>/ Size: {sizeVariation?.size}</span>
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <span className="text-[2.1rem] font-bold text-black">
                {formatCurrency(product.price)}
              </span>
            </div>

            <div className="border-l border-zinc-200 pl-4">
              <span className="text-[1.6rem] text-[#444444]">
                All offers are binding and expire in 48 hours. If the seller
                accepts your offer, payment will be processed.
              </span>
            </div>

            <div className="flex flex-col items-start gap-[1.6rem]">
              <Controller
                control={control}
                name={`price`}
                render={({ field: { value, onChange, onBlur } }) => (
                  <div className="w-full rounded-[1.3rem] border-[1px] border-[#D9D9D9] pb-[1rem] pl-[2rem] pr-[1rem] pt-[1rem] !text-black">
                    <FormLabel
                      labelClasses={`text-[1.8rem] mt-4 ${
                        errors.price ? 'text-brand-secondary' : '!text-black'
                      }`}
                      value={'Your offer'}
                      flex
                      className="items-center"
                    >
                      <FormCurrencyInputFancy
                        placeholder={'0.00'}
                        error={!!errors.price}
                        onChange={(e) => {
                          clearErrors('price');
                          onChange(e);
                        }}
                        value={value > 0 ? value.toFixed(2) : ''}
                        defaultValue={form.price.toString()}
                        onBlur={onBlur}
                        className={`!text-[1.8rem] ${
                          errors.price ? 'text-brand-secondary' : '!text-black'
                        }`}
                      />
                    </FormLabel>
                  </div>
                )}
              />

              <div className="grid w-full grid-cols-3 gap-x-4">
                {[20, 15, 10].map((percentage) => (
                  <button
                    className={`flex cursor-pointer flex-col items-center justify-center rounded-[1.3rem] p-4 ${
                      form.price === getPricePercentageOff(percentage)
                        ? 'border border-brand-secondary bg-brand-secondary text-white'
                        : 'border border-brand-primary-lighter bg-transparent text-black hover:bg-brand-off-white'
                    }`}
                    key={percentage}
                    type="button"
                    onClick={() =>
                      handleOfferClick(getPricePercentageOff(percentage))
                    }
                  >
                    <h1 className={'text-[2rem] font-bold'}>
                      {formatCurrency(getPricePercentageOff(percentage))}
                    </h1>

                    <span
                      className={`${
                        form.price === getPricePercentageOff(percentage)
                          ? 'text-white'
                          : 'text-brand-secondary'
                      } text-[1.6rem] font-semibold`}
                    >
                      {percentage}% off
                    </span>
                  </button>
                ))}
              </div>

              {errors.price && (
                <span className="text-[1.5rem] font-semibold text-brand-red">
                  {errors.price.message}
                </span>
              )}
            </div>
          </div>

          <div className="flex w-full flex-col items-center justify-center gap-[1.6rem] gap-y-8 pb-[2.4rem]">
            <div className="h-px w-full bg-zinc-200" />

            <div className="w-full pt-2">
              <div className="flex items-center gap-x-2">
                <Checkbox
                  label="I acknowledge that this offer is binding."
                  onChange={() => {
                    setValue('termsAndConditions', !form.termsAndConditions);

                    setCheckboxError(false);
                  }}
                  selected={form.termsAndConditions}
                  className="!text-[1.6rem]"
                  checkboxClassName="!h-[1.6rem] !w-[1.6rem] !text-black"
                  error={checkboxError}
                />
              </div>
            </div>

            <Button
              type="secondary"
              text={product.is_flat_rate ? 'Review Offer' : 'Continue'}
              buttonType="button"
              width="normal"
              onClick={() => {
                if (checkErrors().length) {
                  for (const error of checkErrors()) {
                    showErrorToast(error);
                  }
                } else {
                  if (product.is_flat_rate) {
                    if (isEditingBid) {
                      updateBid();
                    } else {
                      reviewShipping();
                    }
                  } else {
                    setCurrentStep(currentStep + 1);
                  }
                }
              }}
              className="w-full text-[1.8rem]"
            />
          </div>
        </div>
      ),
    },
    {
      id: 1,
      content: (
        <div className="absolute flex h-[66rem] w-[44rem] flex-col">
          <div className="flex-1">
            <span className="text-[2.1rem] font-semibold text-black">
              Shipping & Payment
            </span>

            <div className="mt-8 flex flex-col gap-y-6">
              <div className="flex w-full justify-between">
                <span className="text-[1.8rem] font-semibold text-black">
                  Shipping To
                </span>

                <button
                  onClick={() => setOpenModal('address')}
                  className="pr-4 text-[1.5rem] font-medium text-brand-secondary"
                >
                  Add
                </button>
              </div>

              {errors.shippingAddress && (
                <span className="text-[1.5rem] font-semibold text-brand-red">
                  {errors.shippingAddress.message}
                </span>
              )}

              <FormSelect
                disabled={!addressOptions.length}
                allowEmpty={false}
                placeholder="Select shipping address"
                error={!!errors.shippingAddress}
                options={addressOptions}
                rules={{ required: true }}
                control={control}
                name="shippingAddress"
                onChange={(e) => {
                  setSelectedRate(null);
                  clearErrors('shippingAddress');
                  setValue('shippingAddress', e.value);
                  const selectedAddress = JSON.parse(e.value);

                  const shipping = calculateShipping(
                    product,
                    getShippingRegion(selectedAddress)
                  );

                  setShippingCost(shipping?.cost ?? 0);
                }}
              />

              <div className="mt-8 flex w-full justify-between">
                <span className="text-[1.8rem] font-semibold text-black">
                  Payment Method
                </span>

                <button
                  onClick={() => setOpenModal('payment')}
                  className="pr-4 text-[1.5rem] font-medium text-brand-secondary"
                >
                  Add
                </button>
              </div>

              {errors.paymentMethodId && (
                <span className="text-[1.5rem] font-semibold text-brand-red">
                  {errors.paymentMethodId.message}
                </span>
              )}

              <div className="w-full">
                <FormSelect
                  disabled={!paymentOptions?.length}
                  allowEmpty={false}
                  placeholder="Select payment method"
                  error={!!errors.paymentMethodId}
                  options={paymentOptions ?? []}
                  control={control}
                  name="paymentMethodId"
                  onChange={(e) => {
                    setValue('paymentMethodId', e.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="mt-8">
            <Button
              type="secondary"
              text="Continue"
              onClick={() => {
                if (!form.shippingAddress) {
                  showErrorToast('Please select a shipping address');
                  return;
                }

                if (product.is_flat_rate) {
                  setCurrentStep(3);
                } else {
                  setCurrentStep((prev) => prev + 1);
                }
              }}
              className="w-full"
            />
          </div>
        </div>
      ),
    },
    {
      id: 2,
      content: (
        <div className="flex h-[66rem] max-w-[44rem] flex-col justify-between">
          <div className="flex flex-col gap-[2.4rem]">
            <span className="text-[2.1rem] font-semibold text-black">
              Select Shipping
            </span>

            <div className="h-[50rem] overflow-y-auto">
              <OfferShippingOptions
                product={product}
                shipTo={
                  form.shippingAddress
                    ? JSON.parse(form.shippingAddress)
                    : undefined
                }
                setShippingCost={setShippingCost}
                offer_amount={form.price}
                selectedRate={selectedRate}
                setSelectedRate={setSelectedRate}
                ratesError={ratesError}
                setRatesError={setRatesError}
              />
            </div>
          </div>

          <Button
            type="secondary"
            children={
              <>
                {!selectedRate ? (
                  <span className="text-[1.8rem] font-semibold text-white">
                    Continue
                  </span>
                ) : (
                  <div className="flex items-center gap-x-4">
                    <span className="text-[1.8rem] font-semibold text-white">
                      Continue with
                    </span>

                    {selectedRate.carrier_code === 'fedex' ? (
                      <FedExIcon className="h-10 w-10" />
                    ) : selectedRate.carrier_code === 'ups' ? (
                      <UPS className="h-10 w-10" />
                    ) : (
                      <USPSIcon className="h-10 w-10" />
                    )}

                    <span className="text-[1.8rem] font-semibold text-white">
                      {selectedRate.delivery_days ??
                        selectedRate.carrier_delivery_days}{' '}
                      {(selectedRate.delivery_days ??
                        selectedRate.carrier_delivery_days) === 1
                        ? 'Day'
                        : 'Days'}{' '}
                      ({formatCurrency(shippingCost)})
                    </span>
                  </div>
                )}
              </>
            }
            onClick={() => {
              if (!selectedRate) {
                showErrorToast('Please select a shipping rate');
                return;
              }

              if (!form.paymentMethodId) {
                showErrorToast('Please select a payment method');
                return;
              }

              setCurrentStep(4);
            }}
            className="w-full"
            loading={ratesLoading}
          />
        </div>
      ),
    },
    {
      id: 3,
      // review offer - flat rate
      content: (
        <div className="flex h-[66rem] max-w-[44rem] flex-col">
          <div className="flex flex-1 flex-col gap-[2.4rem]">
            <span className="text-[2.1rem] font-semibold text-black">
              Review Offer
            </span>

            <div className="flex items-center justify-between">
              <div className="flex max-w-[30rem] items-center gap-[1.6rem]">
                <SafeImage
                  className="h-[6rem] w-[6rem] shrink-0 rounded-2xl object-cover"
                  alt={'product image'}
                  src={product.thumbnail}
                />

                <div className="flex flex-col gap-[0.2rem]">
                  <div className="flex flex-col gap-y-2">
                    <span className="w-[22rem] truncate text-[1.7rem] font-semibold text-black">
                      {product.title}
                    </span>

                    <span className="text-[1.6rem] font-medium text-[#7F7F7F]">
                      {product.category} / Size: {sizeVariation?.size}
                    </span>
                  </div>
                </div>
              </div>

              <span className="text-[2.1rem] font-bold text-black">
                {formatCurrency(product.price)}
              </span>
            </div>

            <div className="h-px w-full bg-zinc-200" />

            <div className="flex flex-col gap-y-6">
              <div className="flex w-full justify-between">
                <span className="text-[1.8rem] font-semibold text-black">
                  Shipping To
                </span>

                <button
                  onClick={() => setOpenModal('address')}
                  className="pr-4 text-[1.5rem] font-medium text-brand-secondary"
                >
                  Add
                </button>
              </div>

              {errors.shippingAddress && (
                <span className="text-[1.5rem] font-semibold text-brand-red">
                  {errors.shippingAddress.message}
                </span>
              )}

              <FormSelect
                disabled={!addressOptions.length}
                allowEmpty={false}
                placeholder="Select shipping address"
                error={!!errors.shippingAddress}
                options={addressOptions}
                rules={{ required: true }}
                control={control}
                name="shippingAddress"
                onChange={(e) => {
                  setSelectedRate(null);
                  clearErrors('shippingAddress');
                  setValue('shippingAddress', e.value);
                  const selectedAddress = JSON.parse(e.value);

                  const shipping = calculateShipping(
                    product,
                    getShippingRegion(selectedAddress)
                  );

                  setShippingCost(shipping?.cost ?? 0);
                }}
              />

              <div className="mt-8 flex w-full justify-between">
                <span className="text-[1.8rem] font-semibold text-black">
                  Payment Method
                </span>

                <button
                  onClick={() => setOpenModal('payment')}
                  className="pr-4 text-[1.5rem] font-medium text-brand-secondary"
                >
                  Add
                </button>
              </div>

              {errors.paymentMethodId && (
                <span className="text-[1.5rem] font-semibold text-brand-red">
                  {errors.paymentMethodId.message}
                </span>
              )}

              {/* TODO: fix missing cards bug (old bug) */}
              <FormSelect
                disabled={!paymentOptions?.length}
                allowEmpty={false}
                placeholder="Select payment method"
                error={!!errors.paymentMethodId}
                options={paymentOptions ?? []}
                control={control}
                name="paymentMethodId"
                onChange={(e) => {
                  setValue('paymentMethodId', e.value);
                }}
                defaultValue={form.paymentMethodId}
              />

              <div className="mt-4 h-px w-full bg-zinc-200" />

              <div className="flex flex-col gap-y-6">
                <div className="flex items-center justify-between">
                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    Offer Price
                  </span>
                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    {formatCurrency(form.price)}
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    Shipping {product.is_flat_rate && '(Flat Rate)'}
                  </span>

                  {/* TODO: add cheaper rate as default and calculate shipping using it */}
                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    {formatCurrency(shippingCost)}
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    Tax
                  </span>

                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    {formatCurrency(tax)}
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <span className="text-[1.8rem] font-bold text-black">
                    You will pay
                  </span>

                  <span className="text-[2rem] font-bold text-black">
                    {formatCurrency(form.price + shippingCost + tax)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <Button
              type="secondary"
              text={'Submit Offer'}
              buttonType="button"
              width="normal"
              onClick={() => {
                if (isEditingBid) {
                  updateBid();
                } else {
                  handleSubmit(onSubmit)();
                }
              }}
              loading={loadingSubmit}
              className="w-full text-[1.8rem]"
            />
          </div>
        </div>
      ),
    },
    // review offer - dynamic shipping
    {
      id: 4,
      content: (
        <div className="flex h-[66rem] max-w-[44rem] flex-col">
          <div className="flex flex-1 flex-col gap-[2.4rem]">
            <span className="text-[2.1rem] font-semibold text-black">
              Review Offer
            </span>

            <div className="flex items-center justify-between">
              <div className="flex max-w-[30rem] items-center gap-[1.6rem]">
                <SafeImage
                  className="h-[6rem] w-[6rem] shrink-0 rounded-2xl object-cover"
                  alt={'product image'}
                  src={product.thumbnail}
                />

                <div className="flex flex-col gap-[0.2rem]">
                  <div className="flex flex-col gap-y-2">
                    <span className="w-[22rem] truncate text-[1.7rem] font-semibold text-black">
                      {product.title}
                    </span>

                    <span className="text-[1.6rem] font-medium text-[#7F7F7F]">
                      {product.category} / Size: {sizeVariation?.size}
                    </span>
                  </div>
                </div>
              </div>

              <span className="text-[2.1rem] font-bold text-black">
                {formatCurrency(product.price)}
              </span>
            </div>

            <div className="h-px w-full bg-zinc-200" />

            <div className="flex flex-col gap-y-6">
              <div className="flex w-full justify-between">
                <span className="text-[1.8rem] font-semibold text-black">
                  Shipping To
                </span>

                <button
                  onClick={() => setCurrentStep(1)}
                  className="pr-4 text-[1.5rem] font-medium text-brand-secondary"
                  type="button"
                >
                  Edit
                </button>
              </div>

              {errors.shippingAddress && (
                <span className="text-[1.5rem] font-semibold text-brand-red">
                  {errors.shippingAddress.message}
                </span>
              )}

              {form.shippingAddress && (
                <div className="flex items-center gap-x-4">
                  <ShippingLocationIcon className="h-20 w-20" />

                  <div className="flex items-center gap-x-2">
                    <span className="text-[1.8rem] font-medium text-black">
                      {JSON.parse(form.shippingAddress).address_line1},{' '}
                      {JSON.parse(form.shippingAddress).city_locality},{' '}
                      {JSON.parse(form.shippingAddress).state_province}
                    </span>
                  </div>
                </div>
              )}

              <div className="mt-8 flex w-full justify-between">
                <span className="text-[1.8rem] font-semibold text-black">
                  Payment Method
                </span>

                <button
                  onClick={() => setOpenModal('payment')}
                  type="button"
                  className="pr-4 text-[1.5rem] font-medium text-brand-secondary"
                >
                  Edit
                </button>
              </div>

              {errors.paymentMethodId && (
                <span className="text-[1.5rem] font-semibold text-brand-red">
                  {errors.paymentMethodId.message}
                </span>
              )}

              <div className="flex items-center gap-x-4">
                {paymentMethodData?.find(
                  (pm) => pm.id === form.paymentMethodId
                ) && (
                  <>
                    {supportedCardBrands.includes(
                      paymentMethodData.find(
                        (pm) => pm.id === form.paymentMethodId
                      )?.brand ?? ''
                    ) ? (
                      <Image
                        src={`/cc_brands/${paymentMethodData
                          .find((pm) => pm.id === form.paymentMethodId)
                          ?.brand?.toLowerCase()}.png`}
                        alt={
                          paymentMethodData.find(
                            (pm) => pm.id === form.paymentMethodId
                          )?.brand ?? ''
                        }
                        width={32}
                        height={20}
                        className="mr-2 h-12 w-16 object-contain"
                      />
                    ) : (
                      <CreditCardIcon className="h-12 w-12" />
                    )}

                    <span className="text-[1.8rem] font-medium text-black">{`Card ending in ${
                      paymentMethodData.find(
                        (pm) => pm.id === form.paymentMethodId
                      )?.last4
                    }`}</span>
                  </>
                )}
              </div>

              <div className="mt-4 h-px w-full bg-zinc-200" />

              <div className="flex flex-col gap-y-6">
                <div className="flex items-center justify-between">
                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    Offer Price
                  </span>
                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    {formatCurrency(form.price)}
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-x-4">
                    <span className="text-[1.8rem] font-medium text-[#444444]">
                      Shipping{' '}
                    </span>

                    {selectedRate?.carrier_code === 'ups' && (
                      <UPSIcon className="h-16 w-16" />
                    )}
                  </div>

                  {/* TODO: add cheaper rate as default and calculate shipping using it */}
                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    {formatCurrency(shippingCost)}
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    Tax
                  </span>

                  <span className="text-[1.8rem] font-medium text-[#444444]">
                    {formatCurrency(tax)}
                  </span>
                </div>

                <div className="flex items-center justify-between">
                  <span className="text-[1.8rem] font-bold text-black">
                    You will pay
                  </span>

                  <span className="text-[2rem] font-bold text-black">
                    {formatCurrency(form.price + shippingCost + tax)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <Button
              type="secondary"
              text={'Submit Offer'}
              buttonType="button"
              width="normal"
              onClick={() => {
                if (isEditingBid) {
                  updateBid();
                } else {
                  handleSubmit(onSubmit)();
                }
              }}
              loading={loadingSubmit}
              className="w-full text-[1.8rem]"
            />
          </div>
        </div>
      ),
    },
  ];

  const filteredSteps = () => {
    if (product.is_flat_rate) {
      return steps.filter((step) => flatRateList.includes(step.id));
    }

    return steps.filter((step) => dynamicList.includes(step.id));
  };

  // useEffect to lock body scroll when modal is open
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [show]);

  return (
    <>
      <Transition show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-hidden"
          onClose={dismiss}
        >
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="fixed inset-x-0 bottom-0 flex w-full">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <DialogPanel className="relative h-[80rem] w-full">
                  <div
                    className={`flex h-full flex-col rounded-tl-[4rem] rounded-tr-[4rem] bg-white px-4 py-6 shadow-xl ${montserrat.className}`}
                  >
                    <div className="flex h-[6rem] w-full items-center justify-between border-b border-[#F1F1F1] pb-8 pt-4">
                      <div className="flex flex-1">
                        {currentStep > 0 && (
                          <button
                            type="button"
                            onClick={() => {
                              if (currentStep === 1) {
                                setCurrentStep(0);
                              } else {
                                if (product.is_flat_rate) {
                                  const currStep = flatRateList.find(
                                    (s) => s === currentStep
                                  );
                                  const prevStep =
                                    flatRateList[
                                      flatRateList.indexOf(currStep ?? 1) - 1
                                    ];

                                  setCurrentStep(prevStep);
                                } else {
                                  const currStep = dynamicList.find(
                                    (s) => s === currentStep
                                  );
                                  const prevStep =
                                    dynamicList[
                                      dynamicList.indexOf(currStep ?? 1) - 1
                                    ];

                                  setCurrentStep(prevStep);
                                }
                              }
                            }}
                            className="ml-4 text-black"
                          >
                            <ChevronLeftIcon className="h-12 w-12" />
                          </button>
                        )}
                      </div>

                      <span className="flex-1 text-center text-[2.1rem] font-bold text-black">
                        Make an Offer
                      </span>

                      <div className="flex flex-1 justify-end">
                        <button
                          type="button"
                          onClick={() => dismiss(false)}
                          className="mr-4 text-black"
                        >
                          <XMarkIcon className="h-12 w-12" />
                        </button>
                      </div>
                    </div>

                    <div className="mt-12 flex justify-between gap-x-4 px-8">
                      {filteredSteps().map((step) => {
                        const isActive = step.id === currentStep;

                        return (
                          <div
                            key={step.id}
                            className={`h-1 w-full rounded-full ${
                              isActive ? 'bg-brand-secondary' : 'bg-zinc-200'
                            }`}
                          />
                        );
                      })}
                    </div>

                    <div className="p-8">
                      <BProcess steps={steps} currentStep={currentStep} />
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>

      {openModal === 'address' && (
        <AddAddressModal
          isOpen={true}
          dismiss={(address) => {
            setOpenModal('');

            if (address) {
              onAddNewAddress(address);
            }
          }}
        />
      )}

      {openModal === 'payment' && (
        <AddPaymentMethodModal
          isOpen={true}
          dismiss={(paymentMethod) => {
            setOpenModal('');

            if (paymentMethod) {
              onAddNewPaymentMethod(paymentMethod);
            }
          }}
          isFullScreen
        />
      )}
    </>
  );
}
