import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { getItem, logEvent } from '@util/analytics';
import { getNumber } from '@util/createListingHelpers';
import { createBid } from '@util/firestore/bid/bid.service';
import { calculateShipping, getShippingRegion } from '@util/firestore/cart';
import { getPaymentMethods } from '@util/firestore/payments';
import { Rate } from '@util/firestore/shipengine';
import { getTax } from '@util/firestore/taxjar';
import { AddressDocument, addressSchema } from 'models/address';
import { BidDocument } from 'models/bid';
import { ProductDocument, Variation } from 'models/product';
import { UserDocument } from 'models/user';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { getHostUrl } from '..';
import { useRouter } from 'next/navigation';
import { useStripe } from '@stripe/react-stripe-js';
import { getProductById } from '@util/firestore/products';
import { getPublicUserDoc } from '@util/firestore/users';
import { OfferDocument } from 'models/offer';
import {
  createOffer,
  createOfferIntent,
  getOfferId,
  getOfferMessage,
} from '@util/firestore/offers';
import { useToastContext } from 'context/ToastContext';

type useCreateOfferProps = {
  bidInput?: string;
  product: ProductDocument;
  variant?: 'bid' | 'offer';
  highestBid?: BidDocument;
  currentUserBid?: BidDocument;
  userDoc: UserDocument | null | undefined;
  dismiss: (openDrawer?: boolean) => void;
  selectedVariations?: Variation[] | null;
};

export const useCreateOffer = ({
  bidInput,
  product,
  variant,
  highestBid,
  currentUserBid,
  userDoc,
  dismiss,
  selectedVariations,
}: useCreateOfferProps) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedRate, setSelectedRate] = useState<Rate | null>(null);
  const [shippingCost, setShippingCost] = useState(
    !product.is_flat_rate ? selectedRate?.total_amount ?? 0 : 0
  );
  const [tax, setTax] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [checkboxError, setCheckboxError] = useState(false);
  const router = useRouter();
  const stripe = useStripe();
  const { showErrorToast } = useToastContext();

  const flatRateList = [1, 3];
  const dynamicList = [1, 2, 4];

  const { data: paymentMethodData, isLoading: isPaymentDataLoading } = useQuery(
    {
      queryKey: ['paymentMethods'],
      queryFn: getPaymentMethods,
      enabled: !!userDoc,
    }
  );

  const OfferFormSchema = z.object({
    price: z.number().gt(0),
    maxPrice: z.number().optional(),
    message: z.string().optional(),
    shippingAddress: z.string().min(1),
    paymentMethodId: z.string().min(1),
    termsAndConditions: z.boolean().refine((v) => v === true, {
      message: 'You must agree to the terms and conditions',
    }),
  });

  type OfferSchemaType = z.infer<typeof OfferFormSchema>;

  function getPricePercentageOff(percentage: number) {
    const price = getNumber(product.price);

    return Number((price * (1 - percentage / 100)).toFixed(2));
  }

  function getDefaultValue(variation: string, highestBid: number) {
    if (variation === 'offer') {
      return getPricePercentageOff(15);
    }

    return highestBid + 1;
  }

  const defaultMaxBid =
    (currentUserBid?.max_bid ?? 0) > (highestBid?.bid_price ?? 0)
      ? currentUserBid?.max_bid?.toString()
      : '';

  const addressOptions =
    userDoc?.addresses?.map((address) => ({
      id: `${address.address_line1}`,
      // using this validation to avoid showing empty values
      label: [
        address.address_line1,
        address.city_locality,
        address.state_province,
        address.postal_code,
      ]
        .filter(Boolean)
        .join(', '),
      value: JSON.stringify(address),
    })) ?? [];

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<OfferSchemaType>({
    resolver: zodResolver(OfferFormSchema),
    defaultValues: {
      price: getPricePercentageOff(15),
      maxPrice: getNumber(defaultMaxBid),
    },
  });

  const form = watch();

  const calcTax = useCallback(() => {
    const total = getNumber(form.price) + shippingCost;
    if (!form.shippingAddress || !product || !total) return;
    const shippingAddressObj = JSON.parse(form.shippingAddress);
    shippingAddressObj.address_line2 = shippingAddressObj.address_line2 ?? '';
    const shippingAddress = addressSchema.parse(shippingAddressObj);

    getTax({
      product_id: product.id,
      seller_id: product.seller_id,
      to_address: shippingAddress!,
      total,
      shipping: shippingCost,
    }).then((tax) => {
      setTax(tax.amount);
    });
  }, [form.price, form.shippingAddress, product, shippingCost]);

  const updateShipping = async () => {
    if (!form.shippingAddress) {
      setError(
        'shippingAddress',
        {
          type: 'manual',
          message: `No shipping address selected`,
        },
        { shouldFocus: true }
      );

      setLoadingSubmit(false);
      return;
    }

    const shippingAddress = JSON.parse(form.shippingAddress) as AddressDocument;

    const shipping = calculateShipping(
      product,
      getShippingRegion(shippingAddress)
    );

    if (product.is_flat_rate && !shipping) {
      setError(
        'shippingAddress',
        {
          type: 'manual',
          message: `No shipping options available for this product to this address`,
        },
        { shouldFocus: true }
      );
      return;
    }

    if (shipping) {
      setShippingCost(shipping.cost);
    }

    logEvent('add_shipping_info', {
      currency: 'USD',
      items: [getItem(product)],
      shipping_tier: selectedRate?.service_type ?? 'flat_rate',
    });
  };

  const updateBid = async (lastUserBid?: BidDocument) => {
    const bidPrice = getNumber(form.price);
    let maxPrice: number | undefined;

    if (form.maxPrice) {
      maxPrice = getNumber(form.maxPrice);
    }

    if (highestBid && getNumber(form.price) <= highestBid?.bid_price) {
      setError('price', {
        type: 'manual',
        message: `Your bid must be at least $1 higher than the current bid. Please try again.`,
      });
      setCurrentStep(0);
      return;
    }

    if (variant === 'bid' && maxPrice && maxPrice < form.price + 1) {
      setError('maxPrice', {
        type: 'manual',
        message: `Your max bid must be at least $1 higher than your current bid. Please try again.`,
      });
      setCurrentStep(0);
      return;
    }

    let offer_id = lastUserBid?.offer_id ?? currentUserBid?.offer_id;

    let username = userDoc?.username || 'Anonymous';
    username =
      username.charAt(0) + '***' + username.charAt(username.length - 1);

    const newBid = {
      product_id: product.id,
      username: username,
      bid_price: getNumber(form.price),
      ...(maxPrice &&
        maxPrice > bidPrice && {
          max_bid: maxPrice,
        }),
      uid: userDoc?.uid,
      created: Date.now(),
      offer_id,
      auto: false,
    } as BidDocument;

    // if date now is greater than end time, then bid is expired
    if (product.end_time && Date.now() > product.end_time) {
      setError('price', {
        type: 'manual',
        message: `This auction has ended`,
      });
      dismiss();
    }

    createBid(newBid).then(() => {
      dismiss();
    });
  };

  const onSubmit = async (data: OfferSchemaType) => {
    if (!userDoc || !stripe) return;

    const currProduct = await getProductById(product.id);

    if (!currProduct) return;

    if (currProduct.out_of_stock) {
      setError('price', {
        type: 'manual',
        message: `This product is out of stock`,
      });
      setCurrentStep(0);
      return;
    }

    if (!form.termsAndConditions) {
      setError(
        'termsAndConditions',
        {
          type: 'manual',
          message: `You must agree to the terms and conditions`,
        },
        { shouldFocus: true }
      );
    }

    const priceNumber = getNumber(data.price);
    if (
      variant === 'offer' &&
      priceNumber < (currProduct.minimum_offer_price ?? 0)
    ) {
      setError('price', {
        type: 'manual',
        message: `Your offer is lower than the buyers minimum offer price. Please try again.`,
      });
      setCurrentStep(0);
      return;
    }
    if (variant === 'bid' && priceNumber < (highestBid?.bid_price ?? 0)) {
      setError('price', {
        type: 'manual',
        message: `Your bid is lower than current bid. Please try again.`,
      });
      setCurrentStep(0);
    }
    if (variant === 'offer' && priceNumber > currProduct.price) {
      setError('price', {
        type: 'manual',
        message: `Your offer is higher than the buyers asking price. Did you mean to buy now?`,
      });
      setCurrentStep(0);
      return;
    }
    setLoadingSubmit(true);
    const shippingAddressObj = JSON.parse(data.shippingAddress);
    shippingAddressObj.address_line2 = shippingAddressObj.address_line2 ?? '';
    const shippingAddress = addressSchema.parse(shippingAddressObj);
    if (currProduct.is_flat_rate) {
      const shipping = calculateShipping(
        currProduct,
        getShippingRegion(shippingAddress)
      );
      if (!shipping) {
        logEvent(
          'shipping_unavailable',
          {
            items: [getItem(currProduct)],
            currency: 'USD',
          },
          userDoc?.uid
        );
        throw new Error('Shipping Unavailable');
      }
      setShippingCost(shipping.cost);
    }
    const paymentMethod = paymentMethodData?.find(
      (paymentMethod) => paymentMethod.id === data.paymentMethodId
    );
    if (!paymentMethod?.id) throw new Error('Please select a payment method');
    const seller = await getPublicUserDoc({
      uid: currProduct.seller_id,
      noCache: true,
    });
    if (!seller) throw new Error('Seller not found');

    const now = Date.now();

    const letterVariation = selectedVariations?.find((v) => !v.is_number);
    const numberVariation = selectedVariations?.find((v) => v.is_number);

    const timeRemaining =
      variant === 'offer' ? now + 172_800_000 : currProduct.end_time!;

    const offer: OfferDocument = {
      id: getOfferId(),
      is_counter: false,
      is_exclusive: false,
      attribution: {},
      buyer_id: userDoc.uid,
      seller_id: seller.uid,
      rate_id: selectedRate?.rate_id ?? '',
      product_id: currProduct.id,
      current_price: currProduct.price,
      account_id: '', // added in backend
      customer_id: userDoc.customer_id,
      is_auction: variant === 'bid',
      ...(!!currProduct.end_time && {
        end_time: currProduct.end_time,
      }),
      ...(!!data.maxPrice && { max_bid: getNumber(data.maxPrice) }),
      price: getNumber(data.price),
      shipping_cost: shippingCost,
      total: shippingCost + getNumber(data.price) + tax,
      message: data.message ?? '',
      address: shippingAddress,
      payment: paymentMethod,
      uids: [userDoc.uid, seller.uid],
      created: now,
      time_remaining: timeRemaining,
      from_web: true,
      state: 0,
      tax,
      ...(selectedVariations && {
        variation: {
          ...(letterVariation && {
            letter: letterVariation.size as string,
          }),
          ...(numberVariation && {
            number: +numberVariation.size as number,
          }),
        },
      }),
    };

    if (variant === 'offer') {
      offer.message = getOfferMessage(
        currProduct.title,
        offer.price!,
        offer.shipping_cost!,
        offer.tax!,
        offer.message,
        selectedVariations,
        currProduct.size,
        offer.address
      );
    }

    if (!offer.price) {
      setError('price', {
        type: 'manual',
        message: `Please enter a valid price`,
      });
      setCurrentStep(0);
      return;
    }

    if (!currProduct.is_flat_rate && !offer.rate_id) {
      setError('shippingAddress', {
        type: 'manual',
        message: `Please select a shipping rate`,
      });
      setCurrentStep(1);
      return;
    }

    if (!currProduct.is_flat_rate && !offer.shipping_cost) {
      setError('shippingAddress', {
        type: 'manual',
        message: `Please select a shipping rate`,
      });
      setCurrentStep(1);
      return;
    }

    const offerIntent = await createOfferIntent(offer.price, offer.customer_id);
    const return_url = `${getHostUrl()}/dashboard/offers?retry=true`;
    const { error } = await stripe.confirmCardSetup(offerIntent.data, {
      payment_method: paymentMethod.id,
      return_url,
    });

    if (error) {
      setLoadingSubmit(false);
      return;
    } else {
      await createOffer(offer);
      logEvent(
        'created_offer',
        {
          items: [getItem(currProduct)],
          offer_id: offer.id,
        },
        userDoc.uid
      );

      if (variant === 'bid') {
        let username = userDoc.username || 'Anonymous';
        username =
          username.charAt(0) + '***' + username.charAt(username.length - 1);
        const bid: BidDocument = {
          uid: userDoc.uid,
          username: username,
          offer_id: offer.id,
          product_id: currProduct.id,
          bid_price: offer.price,
          ...(!!form.maxPrice && { max_bid: form.maxPrice }),
          created: now,
          auto: false,
        };
        // if date now is greater than end time, then bid is expired
        if (currProduct.end_time && Date.now() > currProduct.end_time) {
          setError('price', {
            type: 'manual',
            message: `This auction has ended`,
          });
          dismiss();
          return;
        }
        await createBid(bid);
      }

      dismiss(true);
      setLoadingSubmit(false);

      router.push('/dashboard/offers');
    }
  };

  const reviewShipping = () => {
    calcTax();
    // if price and terms and conditions are valid, go to review step

    if (!form.termsAndConditions) {
      showErrorToast('You must agree to the terms and conditions');
      return;
    }

    if (!form.shippingAddress) {
      showErrorToast('Please select a shipping address');
      return;
    }

    if (form.price) {
      const priceNumber = getNumber(form.price);
      const maxBid = getNumber(form.maxPrice ?? '0');
      if (
        variant === 'offer' &&
        priceNumber < (product.minimum_offer_price ?? 0)
      ) {
        setError('price', {
          type: 'manual',
          message: `Your offer is lower than the buyers minimum offer price. Please try again.`,
        });
        return;
      }

      if (variant === 'bid' && priceNumber < product.price + 1) {
        setError('price', {
          type: 'manual',
          message: `Your bid must be at least $1 higher than the current bid. Please try again.`,
        });
        setCurrentStep(0);
        return;
      }
      if (variant === 'bid' && maxBid && maxBid < priceNumber + 1) {
        setError('maxPrice', {
          type: 'manual',
          message: `Your max bid must be at least $1 higher than your current bid. Please try again.`,
        });
        setCurrentStep(0);
        return;
      }
      if (variant === 'offer' && priceNumber > product.price) {
        setError('price', {
          type: 'manual',
          message: `Your offer is higher than the buyers asking price. Did you mean to buy now?`,
        });
        return;
      }
      clearErrors('price');
      setCurrentStep(1);
    } else {
      // else, show errors
      if (!form.price) {
        setError('price', {
          type: 'manual',
          message: `Please enter ${variant} price`,
        });
      }
    }
  };

  const onAddNewAddress = (address: AddressDocument) => {
    setValue('shippingAddress', JSON.stringify(address));
  };

  const onAddNewPaymentMethod = (paymentMethodId: string) => {
    setValue('paymentMethodId', paymentMethodId);
  };

  const supportedCardBrands = ['visa', 'mastercard', 'amex'];

  const checkErrors = () => {
    const errors = [];

    if (!form.termsAndConditions) {
      errors.push('Please acknowledge that this offer is binding.');

      setCheckboxError(true);
    }

    return errors;
  };

  return {
    OfferFormSchema,
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    setError,
    clearErrors,
    errors,
    loadingSubmit,
    setLoadingSubmit,
    selectedRate,
    setSelectedRate,
    getPricePercentageOff,
    getDefaultValue,
    flatRateList,
    dynamicList,
    shippingCost,
    setShippingCost,
    paymentMethodData,
    isPaymentDataLoading,
    addressOptions,
    calcTax,
    tax,
    setTax,
    currentStep,
    setCurrentStep,
    updateShipping,
    updateBid,
    onSubmit,
    reviewShipping,
    onAddNewAddress,
    onAddNewPaymentMethod,
    supportedCardBrands,
    createOffer,
    checkErrors,
    checkboxError,
    setCheckboxError,
  };
};
