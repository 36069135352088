import { db } from '@util/firebase';
import {
  collection,
  CollectionReference,
  deleteField,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { BidDocument } from '@models/bid';
import { OfferDocument } from 'models/offer';

export const bidsRef = collection(
  db,
  'bids'
) as CollectionReference<BidDocument>;

export function getBidsByProductIdQuery(productId: string) {
  return query(
    bidsRef,
    where('product_id', '==', productId),
    orderBy('bid_price', 'desc')
  );
}

export async function getBidsCountByProductId(productId: string) {
  const q = getBidsByProductIdQuery(productId);
  const res = await getCountFromServer(q);
  return res.data().count;
}

export async function createBid(bid: BidDocument) {
  const ref = doc(bidsRef);
  return await setDoc(ref, bid);
}

export async function updateOfferShippingOrPayment(
  bid: BidDocument,
  offer: Partial<OfferDocument>
) {
  const offersRef = collection(db, 'offers');
  const offerRef = doc(offersRef, bid.offer_id);
  await updateDoc(offerRef, { ...offer });
}

export async function updateMaxBid(bid: BidDocument, price: number) {
  //  get bid by uid, order by created at, get first
  const q = query(
    bidsRef,
    where('uid', '==', bid.uid),
    where('product_id', '==', bid.product_id),
    orderBy('created', 'desc'),
    limit(1)
  );
  // get the doc ref
  const snapshot = await getDocs(q);
  const ref = snapshot.docs[0].ref;
  await updateDoc(ref, { max_bid: price });
}

export async function deleteMaxBid(bid: BidDocument) {
  //  get bid by uid, order by created at, get first
  const q = query(
    bidsRef,
    where('uid', '==', bid.uid),
    where('product_id', '==', bid.product_id),
    orderBy('created', 'desc'),
    limit(1)
  );
  // get the doc ref
  const snapshot = await getDocs(q);
  const ref = snapshot.docs[0].ref;
  await updateDoc(ref, { max_bid: deleteField() });
}

export async function getHighestBid(product_id: string) {
  const bidsQuery = getBidsByProductIdQuery(product_id);
  const snapshot = await getDocs(bidsQuery);
  const bids = snapshot.docs.map((doc) => doc.data());
  const highestBid = bids.sort((a, b) => b.bid_price - a.bid_price)[0];
  return highestBid ?? null;
}

export async function getUserAuctionOffers(
  uid: string,
  filter: 'all' | 'active' | 'ended'
) {
  // NOTE: fixes firestore access denied errors when uid is empty on initial page load
  if (!uid) {
    return [];
  }
  const offersRef = collection(db, 'offers');
  const filterQuery =
    filter === 'all'
      ? []
      : filter === 'active'
      ? [where('end_time', '>=', Date.now())]
      : [where('end_time', '<', Date.now())];
  const offersQuery = query(
    offersRef,
    where('buyer_id', '==', uid),
    where('is_auction', '==', true),
    ...filterQuery
  );
  const snapshot = await getDocs(offersQuery);
  const offers = snapshot.docs.map((doc) => doc.data());
  return offers as OfferDocument[];
}

export async function getBidsByOfferId(offer_id: string) {
  const bidsQuery = query(bidsRef, where('offer_id', '==', offer_id));
  const snapshot = await getDocs(bidsQuery);
  const bids = snapshot.docs.map((doc) => doc.data());
  return bids;
}

export async function getHighestBidByUser(offer_id?: string, user_id?: string) {
  if (!offer_id || !user_id) return null;
  const bidsQuery = query(
    bidsRef,
    where('offer_id', '==', offer_id),
    where('bidder_id', '==', user_id)
  );
  const snapshot = await getDocs(bidsQuery);
  const bids = snapshot.docs.map((doc) => doc.data());
  const highestBid = bids.sort((a, b) => b.bid_price - a.bid_price)[0];
  return highestBid ?? null;
}
