import { Checkmark } from '@c/icons/checkmark';

const DynamicToast = ({ text, error }: { text: string; error?: boolean }) => {
  return (
    <div className="flex w-min select-none items-center justify-evenly gap-[1.2rem]">
      {error && (
        <div className="flex items-center rounded-full bg-brand-primary p-[0.5rem]">
          <Checkmark variant="white" height={18} width={18} />
        </div>
      )}
      <p className="whitespace-nowrap font-semibold text-brand-black">{text}</p>
    </div>
  );
};

export default DynamicToast;
