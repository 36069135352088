import { ChatFeatureIcon } from '@c/icons';
import { ChatDocument } from '@models/chat';
import { useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import Spinner from '@ui/Spinner';
import { TabItem } from '@ui/Tabs';
import { getAllUnreadMessages } from '@util/firestore/messages';
import { useTabs } from '@util/hooks/useTabs';
import { useAuth } from 'context/AuthContext';
import { useAdminChats } from 'features/messages/hooks/useAdminChats';
import { FiRefreshCcw } from 'react-icons/fi';
import MessagePreview from './MessagePreview';

const Conversations = ({
  conversations,
  onConversationSelected,
  selectedChat,
  loading,
}: {
  conversations: ChatDocument[];
  onConversationSelected: (chat: ChatDocument) => void;
  selectedChat: ChatDocument | undefined;
  loading?: boolean;
}) => {
  return (
    <>
      {loading ? (
        <div className="flex h-full w-full flex-col items-center justify-center gap-[1.6rem] lg:max-h-[60rem]">
          <ChatBubbleIcon className="h-20 w-20" />
          <h3 className="text-[2.4rem] font-medium">Please wait</h3>
          <p>Loading your messages...</p>
          <div className="mx-auto mt-4 h-10 w-10">
            <Spinner />
          </div>
        </div>
      ) : conversations?.length === 0 ? (
        <div className="flex h-full w-full flex-col items-center justify-center gap-[1.6rem] lg:max-h-[60rem]">
          <ChatBubbleIcon className="h-20 w-20" />
          <p className="text-[1.5rem] text-zinc-600">
            Select chat to view the conversation
          </p>
        </div>
      ) : null}
      {!!conversations.length &&
        conversations.map((conversation, index) => {
          if (conversation.id) {
            return (
              <MessagePreview
                chat={conversation}
                selected={selectedChat?.id === conversation.id}
                key={conversation.id + '-' + index}
                onClick={() => {
                  if (conversation.id) {
                    onConversationSelected(conversation);
                  }
                }}
              />
            );
          }
        })}
    </>
  );
};
type ConversationListProps = {
  onConversationSelected: (chat: ChatDocument) => void;
  conversations: ChatDocument[] | undefined;
  selectedChat: ChatDocument | undefined;
  variant: 'messages' | 'offers' | 'orderDetail' | 'experts';
};

export default function ConversationList({
  onConversationSelected,
  selectedChat,
  conversations,
  variant,
}: ConversationListProps) {
  const { userDoc } = useAuth();

  const {
    adminChats,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch,
    adminSellerChats,
    isLoadingSellerChats,
    isFetchingSellerChats,
    isFetchingNextPageSellerChats,
    refetchSellerChats,
    adminBuyerChats,
    isLoadingBuyerChats,
    isFetchingBuyerChats,
    isFetchingNextPageBuyerChats,
    refetchBuyerChats,
    onScrollAll,
    onScrollBuyer,
    onScrollSeller,
    fetchNextPage,
    hasNextPageBuyerChats,
    hasNextPageSellerChats,
    hasNextPage,
    fetchNextPageSellerChats,
    fetchNextPageBuyerChats,
    adminFlaggedChats,
    onScrollFlagged,
    totalAdminBuyerChats,
    totalAdminSellerChats,
  } = useAdminChats({ variant });

  const chats = userDoc?.roles?.admin ? [] : conversations;

  const sellerConversations = chats?.filter(
    (conversation) =>
      conversation.seller_id === userDoc?.uid &&
      conversation.uids?.includes(userDoc?.uid)
  );

  const buyerConversations = chats?.filter(
    (conversation) =>
      conversation.buyer_id === userDoc?.uid &&
      conversation.uids?.includes(userDoc?.uid)
  );

  const { data: allUnread } = useQuery({
    queryKey: ['allUnread'],
    queryFn: () => getAllUnreadMessages(),
    enabled: !!userDoc?.roles?.admin && variant === 'messages',
  });

  const tabItems: TabItem[] = [
    {
      title: `Buying ${
        userDoc?.roles?.admin ? `(${totalAdminBuyerChats})` : ''
      }`,
      unreads: buyerConversations?.reduce(
        (acc, chat) => (chat.unread?.[userDoc?.uid ?? ''] ? acc + 1 : acc),
        0
      ),
      contents: (
        <div
          className="grow overflow-y-auto overscroll-y-contain pb-[10rem]"
          onScroll={onScrollBuyer}
        >
          {!!buyerConversations && (
            <Conversations
              conversations={
                userDoc?.roles?.admin && variant === 'messages'
                  ? adminBuyerChats?.pages.flat().filter((c) => !c.is_auto) ??
                    []
                  : buyerConversations ?? []
              }
              onConversationSelected={onConversationSelected}
              selectedChat={selectedChat}
              loading={
                userDoc?.roles?.admin &&
                variant === 'messages' &&
                (isLoadingBuyerChats ||
                  (isFetchingBuyerChats && !isFetchingNextPageBuyerChats))
              }
            />
          )}
          {userDoc?.roles?.admin && hasNextPageBuyerChats && (
            <Button
              loading={isFetchingNextPageBuyerChats}
              type="tertiary"
              text="Fetch More"
              height="small"
              width={'fluid'}
              onClick={() => fetchNextPageBuyerChats()}
            />
          )}
        </div>
      ),
    },
    {
      title: `Selling ${
        userDoc?.roles?.admin ? `(${totalAdminSellerChats})` : ''
      }`,
      unreads: sellerConversations?.reduce(
        (acc, chat) => (chat.unread?.[userDoc?.uid ?? ''] ? acc + 1 : acc),
        0
      ),
      contents: (
        <div
          className="grow overflow-y-auto overscroll-y-contain pb-[10rem]"
          onScroll={onScrollSeller}
        >
          {!!sellerConversations && (
            <Conversations
              conversations={
                userDoc?.roles?.admin && variant === 'messages'
                  ? adminSellerChats?.pages.flat().filter((c) => !c.is_auto) ??
                    []
                  : sellerConversations ?? []
              }
              onConversationSelected={onConversationSelected}
              selectedChat={selectedChat}
              loading={
                userDoc?.roles?.admin &&
                variant === 'messages' &&
                (isLoadingSellerChats ||
                  (isFetchingSellerChats && !isFetchingNextPageSellerChats))
              }
            />
          )}
          {userDoc?.roles?.admin && hasNextPageSellerChats && (
            <Button
              loading={isFetchingNextPageSellerChats}
              type="tertiary"
              text="Fetch More"
              height="small"
              width={'fluid'}
              onClick={() => fetchNextPageSellerChats()}
            />
          )}
        </div>
      ),
    },
    {
      title: `All`,
      // unreads: userDoc
      //   ? conversations?.reduce(
      //       (acc, chat) => (chat.unread?.[userDoc.uid] ? acc + 1 : acc),
      //       0
      //     )
      //   : undefined,
      contents: (
        <div
          className="grow overflow-y-auto overscroll-y-contain pb-[10rem]"
          onScroll={onScrollAll}
        >
          {!!conversations && (
            <Conversations
              conversations={
                userDoc?.roles?.admin &&
                (variant === 'messages' || variant === 'offers')
                  ? adminChats?.pages
                      .flat()
                      .filter(
                        (c) =>
                          !c.is_auto &&
                          ((variant === 'offers' && c.is_offer) ||
                            (variant === 'messages' && !c.is_offer))
                      ) ?? []
                  : conversations ?? []
              }
              onConversationSelected={onConversationSelected}
              selectedChat={selectedChat}
              loading={
                userDoc?.roles?.admin &&
                variant === 'messages' &&
                (isLoading || (isFetching && !isFetchingNextPage))
              }
            />
          )}
          {userDoc?.roles?.admin && hasNextPage && (
            <Button
              loading={isFetchingNextPage}
              type="tertiary"
              text="Fetch More"
              height="small"
              width={'fluid'}
              onClick={() => fetchNextPage()}
            />
          )}
        </div>
      ),
    },
  ];
  if (userDoc?.roles?.admin && variant === 'messages') {
    tabItems.push({
      title: `Unread (temp) ${allUnread?.length ?? 0} `,
      // unreads: userDoc
      //   ? conversations?.reduce(
      //       (acc, chat) => (chat.unread?.[userDoc.uid] ? acc + 1 : acc),
      //       0
      //     )
      //   : undefined,
      contents: (
        <div
          className="grow overflow-y-auto overscroll-y-contain pb-[10rem]"
          onScroll={onScrollAll}
        >
          {!!allUnread && (
            <Conversations
              conversations={allUnread}
              onConversationSelected={onConversationSelected}
              selectedChat={selectedChat}
            />
          )}
        </div>
      ),
    });
    tabItems.push({
      title: 'Flagged',
      contents: (
        <div
          className="grow overflow-y-auto overscroll-y-contain pb-[10rem]"
          onScroll={onScrollFlagged}
        >
          {!!adminFlaggedChats && (
            <Conversations
              conversations={
                adminFlaggedChats?.pages.flat().filter((c) => !c.is_auto) ?? []
              }
              onConversationSelected={onConversationSelected}
              selectedChat={selectedChat}
            />
          )}
        </div>
      ),
    });
  }

  const { currentBody, tabs } = useTabs({ tabItems, full: true, initial: 2 });
  if (!userDoc) return null;

  return (
    <div className="flex h-full flex-col overflow-y-auto border-0 lg:border-r-[1px]">
      <span className="px-[1.6rem] pt-[1.6rem] text-[1.8rem] font-semibold lg:hidden">
        Messages
      </span>

      {userDoc?.roles?.admin && (
        <div className="flex w-full gap-4 p-4">
          <Button
            key="buyer"
            leadingIcon={<FiRefreshCcw width={24} height={24} />}
            text="(buyer)"
            width="fluid"
            height="extraSmall"
            onClick={() => {
              refetchBuyerChats();
            }}
          />
          <Button
            key="seller"
            leadingIcon={<FiRefreshCcw width={24} height={24} />}
            text="(seller)"
            width="fluid"
            height="extraSmall"
            onClick={() => {
              refetchSellerChats();
            }}
          />
          <Button
            key="all"
            leadingIcon={<FiRefreshCcw width={24} height={24} />}
            text="(all)"
            width="fluid"
            height="extraSmall"
            onClick={() => {
              refetch();
            }}
          />
        </div>
      )}
      <div className="shrink">{tabs}</div>
      {currentBody}
    </div>
  );
}

export const ChatBubbleIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M33.6713 25.0469C33.5931 24.7656 33.765 24.375 33.9291 24.0937C33.9792 24.01 34.034 23.9292 34.0931 23.8516C35.4965 21.7663 36.2471 19.3104 36.2494 16.7969C36.2728 9.59375 30.1947 3.75 22.6791 3.75C16.1244 3.75 10.6556 8.21094 9.37439 14.1328C9.18271 15.0105 9.08581 15.9063 9.08533 16.8047C9.08533 24.0156 14.9291 30.0156 22.4447 30.0156C23.64 30.0156 25.2494 29.6562 26.1322 29.4141C27.015 29.1719 27.89 28.8516 28.1166 28.7656C28.3488 28.6781 28.5949 28.6331 28.8431 28.6328C29.1139 28.6318 29.3822 28.6849 29.6322 28.7891L34.0619 30.3594C34.159 30.4005 34.2617 30.4268 34.3666 30.4375C34.5323 30.4375 34.6913 30.3717 34.8085 30.2544C34.9257 30.1372 34.9916 29.9783 34.9916 29.8125C34.9861 29.741 34.973 29.6703 34.9525 29.6016L33.6713 25.0469Z"
        stroke="#7F7F7F"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M5.19223 18.125C4.16757 19.9658 3.67189 22.0541 3.76 24.1591C3.8481 26.264 4.51655 28.3036 5.69145 30.0523C5.87192 30.325 5.97348 30.5359 5.94223 30.6773C5.91098 30.8187 5.0102 35.5109 5.0102 35.5109C4.98854 35.6207 4.99676 35.7344 5.034 35.8399C5.07124 35.9454 5.13614 36.039 5.22192 36.1109C5.33638 36.2021 5.4787 36.2512 5.62505 36.25C5.70325 36.2502 5.78065 36.2343 5.85239 36.2031L10.2438 34.4844C10.546 34.3652 10.8832 34.3708 11.1813 34.5C12.661 35.0766 14.2969 35.4375 15.9336 35.4375C18.1299 35.4398 20.2877 34.8606 22.1875 33.7586"
        stroke="#7F7F7F"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
