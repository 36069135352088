import { formatRange, isMobile } from '@util/index';
import { useAuth } from 'context/AuthContext';
import dayjs from 'dayjs';
import { ProductDocument } from 'models/product';
import { UserDocument } from 'models/user';
import Link from 'next/link';

export default function ProductConditions({
  product,
  deliveryDates,
  toggleFitmentWarning,
}: {
  product: ProductDocument;
  deliveryDates?: {
    earliest: string;
    latest: string;
  };
  toggleFitmentWarning: () => void;
}) {
  const { userDoc } = useAuth();

  let dates = deliveryDates;

  if (product.is_flat_rate) {
    dates = {
      earliest: dayjs().add(5, 'days').toISOString(),
      latest: dayjs().add(10, 'days').toISOString(),
    };
  }

  const getDeliveryText = () => {
    return formatRange(dates);
  };

  return (
    <div className="flex flex-col gap-y-4">
      <Item title="Condition" value={product.condition} />

      <DeliveryItem
        deliveryDates={deliveryDates}
        userDoc={userDoc}
        getDeliveryText={getDeliveryText}
      />

      {product.category.includes('Parts') && (
        <div className="flex items-center gap-x-2">
          <span className="text-[1.6rem] font-semibold text-brand-black">
            Compatibility:
          </span>

          <button
            type="button"
            className="text-[1.6rem] font-medium text-brand-black underline"
            onClick={() => {
              toggleFitmentWarning?.();
              const elementId = isMobile() ? 'mobileFitment' : 'fitment';
              const fitmentSection = document.getElementById(elementId);
              if (fitmentSection) {
                setTimeout(() => {
                  fitmentSection.scrollIntoView({ behavior: 'smooth' });
                }, 0);
              }
            }}
          >
            See fitment details
          </button>
        </div>
      )}

      {product.category.includes('Gear') ||
        (product.category.includes('Casual') &&
          !product?.variations &&
          product.size?.letter !== '' && (
            <Item
              title="Size"
              value={
                product.size?.letter
                  ? product.size?.letter
                  : product.size?.number
                  ? product.size?.number.toString()
                  : ''
              }
            />
          ))}

      {product.sku && <Item title="SKU" value={product?.sku} />}
    </div>
  );
}

const DeliveryItem = ({
  deliveryDates,
  userDoc,
  getDeliveryText,
}: {
  deliveryDates:
    | {
        earliest: string;
        latest: string;
      }
    | undefined;
  userDoc: UserDocument | null | undefined;
  getDeliveryText: () => string | null;
}) => {
  const showLoading = !deliveryDates && userDoc;

  const today = dayjs();
  const threeDaysFromNow = today.add(3, 'days');

  return (
    <div className="flex items-center gap-x-2">
      <span className="text-[1.6rem] font-semibold text-brand-black">
        Delivery:
      </span>

      <span className="flex items-center gap-x-2 text-[1.6rem] text-brand-black">
        Get it by{' '}
        {showLoading ? (
          <div className="h-5 w-32 animate-pulse rounded-full bg-brand-light-gray" />
        ) : (
          <>
            {!userDoc ? (
              <span className="font-semibold">
                {today.format('MMM D')} - {threeDaysFromNow.format('MMM D')}
              </span>
            ) : (
              <span className="font-semibold">{getDeliveryText()}</span>
            )}
          </>
        )}{' '}
        if you order today!
      </span>
    </div>
  );
};

const Item = ({
  title,
  value,
  linkText,
  linkHref,
}: {
  title: string;
  value?: string;
  linkText?: string;
  linkHref?: string;
}) => {
  return (
    <div className="flex items-center gap-x-2">
      <span className="text-[1.6rem] font-semibold text-brand-black">
        {title}:
      </span>

      {linkHref ? (
        <Link
          href={linkHref}
          className="text-[1.6rem] text-brand-black underline"
        >
          {linkText}
        </Link>
      ) : (
        <span className="text-[1.6rem] text-brand-black">{value}</span>
      )}
    </div>
  );
};
