import MobileMakeAnOfferModal from '@c/modals/MobileMakeAnOfferModal';
import ProductCouponDisplay from '@c/ProductCouponDisplay';
import { PublicUserDocument } from '@models/user';
import { Elements } from '@stripe/react-stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import { logInitCheckout } from '@util/analytics';
import { getCartItemsSubtotal } from '@util/firestore/cart';
import {
  addProductToFavorites,
  removeProductFromFavorites,
} from '@util/firestore/products';
import { useOnlineStatus } from '@util/hooks/useOnlineStatus';
import { getCartItemFromProductDocument, isMobile } from '@util/index';
import { useAttributionContext } from 'context/AttributionContext';
import { useAuth } from 'context/AuthContext';
import { useChat } from 'context/ChatContext';
import { useShoppingCart } from 'context/ShoppingCartContext';
import { useStripeContext } from 'context/StripeContext';
import { useToastContext } from 'context/ToastContext';
import dayjs from 'dayjs';
import { HeartIcon } from 'lucide-react';
import { ProductDocument, Variation } from 'models/product';
import dynamic from 'next/dynamic';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useState } from 'react';
const MakeAnOfferModal = dynamic(() => import('../modals/MakeAnOfferModal'));

export function hasValidVariations(
  product: ProductDocument,
  selectedVariations: Variation[] | null
) {
  return (
    !product.has_variations ||
    (product.category2 === 'Gear Combos' && selectedVariations?.length === 2) ||
    (product.category2 !== 'Gear Combos' &&
      (selectedVariations?.length ?? 0) >= 1)
  );
}

export const BuyAddOfferButtons = ({
  product,
  seller,
  selectedVariations,
  onAddToCartError,
  hideOffer,
}: {
  product: ProductDocument;
  seller?: PublicUserDocument | null;
  selectedVariations: Variation[] | null;
  onAddToCartError: () => void;
  hideOffer?: boolean;
}) => {
  const { userDoc, user, signInAnon } = useAuth();
  const { increaseCartQty, canAddProductToCart, setCartOpen, cart } =
    useShoppingCart();
  const [openModal, setOpenModal] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [liked, setLiked] = React.useState(false);
  const { setChatOpen } = useChat();
  const { stripePromise } = useStripeContext();
  const { getAttribution } = useAttributionContext();
  const router = useRouter();
  const pathname = usePathname();
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastContext();
  const [nextAction, setNextAction] = useState<'' | 'buyNow' | 'addToCart'>('');

  React.useEffect(() => {
    if (userDoc) {
      const isFavorite = userDoc.favorites.includes(product.id);
      setLiked(isFavorite);
    }
  }, [userDoc, product.id]);

  useEffect(() => {
    if (nextAction && user && cart) {
      if (nextAction === 'buyNow') {
        handleBuyNow();
      } else if (nextAction === 'addToCart') {
        handleAddToCart();
        setLoadingAddToCart(false);
      }
      setNextAction('');
    }
  }, [nextAction, user, cart]);

  const [loadingAddToCart, setLoadingAddToCart] = useState(false);
  const handleAddToCart = async () => {
    if (user) {
      if (!hasValidVariations(product, selectedVariations)) {
        showErrorToast('Invalid variations');

        onAddToCartError();
        return;
      }

      if (!canAddProductToCart(product.id) && showBuyButton) {
        showErrorToast('Cannot add product to cart');

        setCartOpen(true);
        return;
      }

      const attribution = getAttribution(product.id);

      const cartItem = getCartItemFromProductDocument(
        product,
        selectedVariations,
        attribution
      );

      increaseCartQty(cartItem, false);
    } else {
      try {
        setLoadingAddToCart(true);
        await signInAnon();
        setNextAction('addToCart');
      } catch (e) {
        setLoadingAddToCart(false);
        showErrorToast((e as Error).message);
      }
    }
  };

  const [loadingBuyNow, setLoadingBuyNow] = useState(false);
  const handleBuyNow = async () => {
    if (user) {
      if (hasValidVariations(product, selectedVariations)) {
        if (canAddProductToCart(product.id) && showBuyButton) {
          const cartItem = getCartItemFromProductDocument(
            product,
            selectedVariations
          );
          increaseCartQty(cartItem, true); // true to skip opening the cart drawer
        }
        logInitCheckout(
          [product],
          getCartItemsSubtotal([
            getCartItemFromProductDocument(product, selectedVariations),
          ]),
          user.uid
        );
        router.push('/checkout');
      } else {
        onAddToCartError();
      }
    } else {
      try {
        setLoadingBuyNow(true);
        await signInAnon();
        setNextAction('buyNow');
      } catch (e) {
        setLoadingBuyNow(false);
        showErrorToast((e as Error).message);
      }
    }
  };

  const handleLikeClick = async () => {
    if (!userDoc) return;
    const isFavorite = userDoc?.favorites.includes(product.id);
    if (isFavorite) {
      await removeProductFromFavorites(userDoc.uid, product.id);
    } else {
      await addProductToFavorites(userDoc.uid, product.id);
    }

    setLiked(!liked);

    queryClient.invalidateQueries({
      queryKey: ['authUser'],
    });
  };

  const { status } = useOnlineStatus(seller?.uid);
  const today = dayjs();
  const lastChangeDate = dayjs(status?.last_changed);
  const lastSeenInWeeks = today.diff(lastChangeDate, 'week');

  const showBuyButton = !userDoc || (status && lastSeenInWeeks < 2);

  const handleMakeAnOfferClick = (mobile: boolean = false) => {
    if (userDoc && !user?.isAnonymous) {
      if (hasValidVariations(product, selectedVariations)) {
        if (mobile) {
          setOpenDrawer(true);
        } else {
          setOpenModal(true);
        }
      } else {
        onAddToCartError();
      }
    } else {
      router.push(`/login?redirect=${encodeURI(pathname ?? '')}`);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-[1rem]">
        <div className="block w-full sm:hidden">
          <ProductCouponDisplay product={product} />
        </div>

        {showBuyButton && (
          <Button
            type="secondary"
            text="Buy It Now"
            width="fluid"
            loading={loadingBuyNow}
            disabled={product.out_of_stock}
            onClick={handleBuyNow}
            className="!rounded-[1.2rem] !text-[1.8rem]"
          />
        )}

        {showBuyButton && (
          <Button
            text="Add to Cart"
            width="fluid"
            type="outline"
            loading={loadingAddToCart}
            disabled={product.out_of_stock}
            onClick={handleAddToCart}
            className="!rounded-[1.2rem] !border-brand-gray !text-[1.8rem] !text-black"
          />
        )}

        {hideOffer ? (
          <div className="hidden sm:flex">
            <Button
              type="outline"
              width="fluid"
              text={liked ? 'In Favorites' : 'Add to Favorites'}
              leadingIcon={
                <HeartIcon
                  className={`${
                    liked ? '!fill-white' : '!text-black'
                  } h-10 w-10`}
                />
              }
              disabled={product.out_of_stock}
              onClick={handleLikeClick}
              className={`!rounded-[1.2rem] !border-brand-gray !text-[1.8rem] ${
                liked
                  ? '!border-none !bg-brand-secondary !text-white'
                  : '!border-black !bg-inherit !text-black'
              }`}
            />
          </div>
        ) : (
          <>
            <Button
              type="outline"
              width="fluid"
              text="Make An Offer"
              disabled={product.out_of_stock}
              onClick={() => {
                handleMakeAnOfferClick(isMobile());
              }}
              className="!rounded-[1.2rem] !border-brand-gray !text-[1.8rem] !text-black sm:flex"
            />
          </>
        )}
      </div>

      {openModal && (
        <Elements stripe={stripePromise}>
          <MakeAnOfferModal
            isOpen={openModal}
            dismiss={(openDrawer) => {
              setOpenModal(false);
              if (openDrawer) {
                if (isMobile()) {
                  router.push('/dashboard/offers');
                } else {
                  setChatOpen(true);
                }
              }
            }}
            product={product}
            selectedVariations={selectedVariations}
          />
        </Elements>
      )}

      {openDrawer && (
        <Elements stripe={stripePromise}>
          <MobileMakeAnOfferModal
            show={openDrawer}
            dismiss={() => setOpenDrawer(false)}
            product={product}
            selectedVariations={selectedVariations}
          />
        </Elements>
      )}
    </>
  );
};

export default BuyAddOfferButtons;
